@import 'themes/colors';
@import 'themes/fonts';

.panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid $IndyWorkBlack_d;
  height: 100%;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  right: 0;
  overflow-x: hidden;
  z-index: 2;
  max-width: 500px;
  background-color: $IndyWorkPurple_d;
}

.sidePanelOpen {
  composes: panel;
  width: 100%;
  padding: 20px;
}

.sidePanelClosed {
  composes: panel;
  width: 0;
  padding: 0;
}

.pageWrapper {
  width: 100%;
  height: 100vh;
  overflow:hidden;
  min-width: 1200px;
  padding: 60px 40px 0;
}

.draggingMemo {
  font-family: $Roboto;
  font-size: 16px;
  font-style: italic;
  color: $IndyWorkGray_dd;  
}
