@import 'src/sass/error-styles';
@import 'src/sass/heading';
@import '../../../../sass/layout-styles.module';
@import 'src/sass/themes/colors';

.warning {
  color: $IndyWorkGray_d;
  font-size: 1.5em;
  text-align: center;
}
