@import 'src/sass/layout-styles.module';
@import 'src/sass/error-styles';
@import 'src/sass/buttons/button.module';
@import 'src/sass/heading';


.employeeSearchWrapper {
  composes: pageWrapper;

  .employeeSearchHeader {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .left {
      flex: 1 0;
      display: flex;
      align-items: center;
    }

    .right {
      flex: 1 0;
      display: flex;
      justify-content: flex-end;

      div {
        width: 320px;
        margin-left: 20px;
      }
    }
  }
}

.addEmployeeLink {
  composes: invertedBtnGreen;
  padding: 8px 18px;
  margin-right: 20px;
  text-decoration: none;
}
