@import 'src/sass/buttons/modern-btn.module';

.coursesSortWrapper {
  display: flex;
  margin-bottom: 40px;

  label {
    align-self: center;
    margin: 0 20px 0 auto;
  }

  button {
    margin-right: 20px;
    width: 80px;
  }

  div {
    select {
      padding: 8px;
    }
    svg {
      top: 10px;
    }
  }
}
