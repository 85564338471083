@import '../../../sass/buttons/button.module';

.inviteForm {
  display: grid;
  grid-row-gap: 20px;

  &:nth-child(2) { margin-bottom: 20px; }
}

.inviteCode {
  color: white;
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button { width: 120px; }
}

.actionRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    padding-right: 10px;
    font-size: 14px;
  }
}
