@import 'src/sass/themes/colors';
@import 'src/sass/heading';
@import 'src/sass/buttons/button.module';

.calHeader {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  flex: 0 1 40px;
}

.calHeadLeft {
  flex: 0 1 350px;
  h1 {
    margin-bottom: 5px;
  }
  header {
    display: flex;
    width: 30vw;
  }
}

.calHeadRight {
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
}

.calDate {
  color: $IndyWorkPurpleNew;
  font-size: 24px;
}

.calDateControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  margin-right: 20px;

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: white;
    font-size: 21px;
    :hover {
      color: $IndyWorkPurpleNew;
    }

    &:disabled {
      color: $WebBusinessBlue_d;
    }
  }

  & + div {
    width: 100px;
  }
}


