@import 'src/sass/themes/colors';

.preApprovedRow {
  color: $white;
  font-size: 14px;
  border-bottom: 1px solid $IndyWorkPurple_d;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  display: flex;
  padding-right: 20px;
}

.deleteBtn {
  background-color: transparent;
  border: 0;
  color: $white;
  cursor: pointer;

  &:hover {
    color: $IndyWorkPurple_d;
  }

  svg {
    font-size: 16px;
    stroke-width: 2px;
  }
}
