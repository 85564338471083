@import 'src/sass/modal';
.modalUnverify {
  composes: modalWrapper;
  width: 100%;
  max-width: 800px;
  padding: 10px 20px 30px;

  h3 {
    font-size: 24px;
    font-weight: bold;    
  }
}

