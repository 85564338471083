@import 'src/sass/layout-styles.module';
@import 'src/sass/heading';
@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';

.verificationHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sortWrapper {
  display: flex;
  align-items: center;
}

.filterWrapper {
  display: flex;
  align-items: center;

  .verificationSearchBar {
    width: 220px;
  }

  .filterDropdown {
    margin-left: 10px;

    div {
      svg {
        top: 13px;
        right: 5px;
      }

      select {
        color: $IndyWorkPurpleNew;
        padding: 8px 23px 8px 8px;
      }
    }
  }

  .verificationBtnGroup {
    button {
      margin-left: 10px;
      color: $IndyWorkPurpleNew;
      background-color: transparent;

      &.active {
        color: $white;
        background-color: $IndyWorkPurpleNew;
      }
    }
  }
}

