@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  border: dashed 1px #393960;
  font-family: $Roboto;
  cursor: default !important;

  &.isRound {
    border-radius: 100%;
  }

  svg {
    margin-bottom: 10px;
    color: $IndyWorkPurpleNew;
  }

  .p1 {
    text-align: center;
    cursor: default;
    font-size: 14px;
    margin: 0 auto;
    width: 100px;
  }
  .p2 {
    cursor: default;
    margin: 0;
    font-size: 12px;
  }
  .p3 {
    cursor: default;
    font-size: 10px;
    color: $IndyWorkGray_d;
  }

  button {
    background-color: transparent;
    border: 0;
    color: $IndyWorkPurpleNew;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
}
