@import 'themes/colors';
@import 'themes/fonts';

.h1Heading {
  color: $white;
  font-family: $Roboto;
  font-weight: bold;
  font-size: 32px;
  margin: 0 20px 0 0;  
  min-height: unset;
  &::first-letter{
    text-transform: capitalize;
  }
}
