@import '../../../sass/themes/colors';

.errorPageWrapper {
  width: 100%;
  margin-top: 200px;

  h1 {
    text-align: center;
    color: $IndyWorkRed;
  }
}
