@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';

.listItem {
  width: 225px;
  height: 425px;
  padding: 1.25em;
  border-radius: 4px;
  background-color: transparent;
  border: 3px solid transparent;
  color: $IndyWorkGray_d;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: $IndyWorkNavBar;
    cursor: pointer;
  }

  &.selected {
    border: 3px solid $IndyWorkPurpleNew;
  }
}

.area {
  margin: 5px 1px;
}

.thumbnail {
  width: 225px;
  height: 225px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

.img {
  width: 225px;
  height: 225px;
  border-radius: 4px;
  &.closed {
    filter:grayscale(1)
  }
}

.name {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title {
  font-size: 14px;
}

.actionArea {
  margin: 5px 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewLink {
  composes: invertedBtnBlue;
  padding: 8px 0;
  width: 78px;
  text-decoration: none;
}
