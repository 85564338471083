@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.InputFormNumber {
  font-size: 14px;
  color: $IndyWorkWhite_d;
  font-family: $Roboto;
  background-color: $IndyWorkNavBar;
  border: 1px solid $IndyWorkNavBar;
  transition: opacity 0.2s;
  padding: 12px 8px;
  border-radius: 2px;

  &:hover,
  &:active,
  &:focus {
    color: $white;
    border: 1px solid $IndyWorkPurpleNew;
  }

  &:disabled {
    font-style: italic;
    color: $IndyWorkGray_dd;
    background-color: $ScrollBar;
    border: 1px solid $ScrollBar;
    overflow-y: scroll;
    cursor: not-allowed;
    height: 42px;
  }

  &.error {
    border: 1px solid $IndyWorkRed_l;
  }
}
