@import 'src/sass/themes/colors';

%badgeCircle {
  background-color: $IndyWorkRed;
  border-radius: 25px;
  font-size: 11px;
  width: 25px;
  height: 15px;
  text-align: center;
  color: white !important;
  margin-left: auto;
  line-height: 16px;
}

.badgeCircleRed {
  @extend %badgeCircle;
  background-color: $IndyWorkRed;
}

.badgeCirclePurple {
  @extend %badgeCircle;
  background-color: $IndyWorkPurpleNew;
}

.badgeCircleGreen {
  @extend %badgeCircle;
  background-color: $IndyWorkGreen;
}

.badgeCirclePink {
  @extend %badgeCircle;
  background-color: $WorkerPink;
}

.badgeCircleBlue {
  @extend %badgeCircle;
  background-color: $IndyWorkBlue;
}

.badgeCircleOrange {
  @extend %badgeCircle;
  background-color: $IndyWorkOrange_l;
}