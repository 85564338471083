@import 'src/sass/themes/colors';

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  button {
    cursor: pointer;
    flex: 0 1 100px;
    padding: 10px 40px;
    font-size: 1.1rem;
    white-space: nowrap;
    text-align: center;
    background-color: transparent;
    outline: none;
    color: $IndyWorkGray_d;
    border: 1px solid transparent;
    border-bottom: 1px solid $IndyWorkPurple_d;
    border-radius: 0;
    font-weight: normal;
    &::first-letter {
      text-transform: capitalize;
    }
    transition: font-weight 0.2s;

    &.active {
      color: white;
      border: 1px solid $IndyWorkPurple_d;
      border-bottom: 1px solid transparent;
      border-radius: 2px 2px 0 0;
    }

    &.inactive:hover { color: white; }
    :disabled { color: $IndyWorkBlack_l; }
  }
}

.empty {
  flex: 1;
  align-self: stretch;
  border-bottom: 1px solid $IndyWorkPurple_d;
}
