@import 'src/sass/modal';

.inviteModal {
  composes: modalWrapper;
  min-width: 690px;
  max-width: 800px;
  min-height: 520px;
  padding: 20px;

  form {
    margin-top: 20px;
  }
}
