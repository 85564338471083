@import 'src/sass/error-styles';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/modal-constants';
@import 'booking-label-styles.modules';

.bookingGroupSelectField {
  button {
    background-color: $modalInputBg !important;
    color: $modalInputColor !important;

    &:disabled {


      background-color: $ScrollBar !important;
      color: $IndyWorkGray_dd !important;
    }
  }
}

