.navClubList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.alertWrapper {
  position: relative;
}

.circle {
  background-color: red;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  bottom: 4px;
}
