@import 'src/sass/modal';
@import 'src/sass/themes/colors';

.modalAddGroups {
  composes: modalWrapper;
  width: 900px;
  height: 700px;
  padding: 12px 34px;
  background-color: $ScrollBar;
}
