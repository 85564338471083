@import 'src/sass/themes/colors';

.pageHeading {
  font-size: 16px;
}

.mainText {
  margin: 0 0 40px;
  line-height: 24px;
}

.userForm {
  max-width: 340px;
  width: 340px;
  padding-top: 10px;
}

.submitBtn {
  margin-top: 48px;
  background-color: $WorkerPink;
  border: 0;
  border-radius: 25px;
  color: $white;
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  font-weight: bold;
  :hover {
    background-color: $white;
    color: $WorkerPink;
  }

  :disabled {
    cursor: not-allowed;
    background-color: $WorkerPink_d;
    color: $IndyWorkGray_d;
  }
}

.timeOut {
  color: $white;
  background-color: rgba(92, 74, 167, .7);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 40px;
  font-size: 12px;

  div:first-child {
    font-size: 14px;
    font-weight: bold;
  }
}

.terms {
  max-width: 920px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $white;

  @media (min-width: 720px) {
    margin-top: 80px;
  }
}

.activeLink {
  text-decoration: none;
  font-weight: bold;
  color: $WorkerPink;
  margin-top: 40px;
  display: block;
}
