@import 'src/sass/themes/colors';
@import 'src/sass/scroll-bar';

.bookingListWrapper {
  height: 100%;
  width: 100%;
  padding: 0 0 0 15px;
}

.bookingListTitle {
  flex: 0 1 20px;
  font-size: 12px;
  color: $IndyWorkPurpleNew;
  &::first-letter {
    text-transform: capitalize;
  }
}

.bookingListDate {
  flex: 0 1 30px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid $IndyWorkPurple_d;
}

.bookingList {
  composes: scrollBar;
  flex: 1 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
