@import 'src/sass/themes/colors';
@import 'src/sass/scroll-bar';

.iconWrapper {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  left: 15px;
  z-index: 2;
  opacity:0.75;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  svg {
    font-size: 24px;
    color: white;
    position: relative;
  }
}

.navWrapper {
  composes: scrollBar;
  transition: all 0.4s;
  height: 100%;
  flex: 0 1 300px;
  padding: 20px 20px 0 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $IndyWorkBlack_d;
  background-color: $IndyWorkNavBar;
  margin-left:0;

  &.hide { 
    transition: all 0.5s;
    margin-left: -240px;
    opacity:0;
  }

  footer {
    border-top: 1px solid $IndyWorkPurple_d;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: auto 0 0;

    a {
      text-decoration: none;
      font-size: 14px;
      color: $IndyWorkPurpleNew;

      :hover {
        color: $white;
      }
    }
  }
}

.manageArea {
  margin-top: 20px;
  margin-bottom: 5px;

  p {
    color: $IndyWorkPurpleNew;
    &::first-letter {
      text-transform: capitalize;
    }
    font-size: 12px;
  }
}

.build {
  font-size: 10px;
  margin: 10px auto;
  color: $IndyWorkCB_gray;
}

.bugBtn {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
  color: $IndyWorkCB_gray;
  cursor: pointer;
  align-self: start;
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: $IndyWorkPurpleNew;
  }
}
