.darkOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  z-index: 3;
  background-color: rgba(24,26,42,0.9);  
}
