@import 'src/sass/themes/colors';

.noItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    color: $IndyWorkBlack_l;
    font-size: 1rem;
    font-weight: bold;
  }
}
