@import 'src/sass/buttons/button.module';

.bookingModalActionArea {
  grid-area: ActionArea;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;

  button {
    width: 110px;

    &:first-child {
      margin-right: 20px;
    }
  }
}
