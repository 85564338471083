@import 'src/sass/themes/colors';

.userAreaWrapper {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0;
  }

  a {
    color: white;
    display: block;
    &:hover { color: white }
  }
}

.nameArea {
  display: flex;
  align-items: center;
  span {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $white;
    margin-right: 5px;
  }
}

.roleArea {
  color: $IndyWorkCB_gray;
  font-size: 12px;
}

.userAreaDropdown {
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: bold;

  ul {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style-type: none;
    top: 20px;
    left: -10px;
    width: 150px;
    border-radius: 4px;
    background-color: $IndyWorkPurple_d;
    z-index: 15;

    & > a {
      color: $white;
      cursor: pointer;
      padding: 10px 16px;
      transition: font-size 0.2s;
      font-weight: normal;
      border-bottom: 1px solid $IndyWorkNavBar;
      text-decoration: none;

      &:hover {
        font-size: 14px;        
        background-color: $IndyWorkPurple_l;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom: 0;
      }
    }

    /* Arrow Styling */
    &::after {
      bottom: 100%;
      border: solid 7px rgba(76, 86, 128, 0);
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $IndyWorkPurple_d;
      margin-left: -7px;
      left: 12%;
    }
  }
}
