@import '../themes/colors';

time {
  color: white;
}

.react-calendar {
  background: transparent;
  border: 1px solid $IndyWorkPurple_d;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 420px;
  text-align: center;

  &::first-letter {
    text-transform: capitalize;
  }
  font-size: 10px;
  font-weight: bold;

  button {
    border: 1px dashed transparent;
    align-self: baseline;

    &:enabled:hover {
      border: 1px solid white;
    }
  }

  &__tile {
    border-radius: 0;
    color: white;
    background-color: transparent;
    height: 35px;

    &:enabled:hover,
    &:enabled:focus {
      background-color: transparent;
      border-radius: 0;
      border: 1px dashed white;
    }

    &:disabled, .react-calendar__navigation__arrow:disabled {
      background-color: transparent !important;
      color: $IndyWorkGray_d;
      cursor: not-allowed;
    }

    &.today {
      border: 1px dashed $IndyWorkPurpleNew;
    }

    &--active {
      background: transparent;
      border: 1px solid $IndyWorkPurpleNew !important;
      color: $IndyWorkPurpleNew;
      font-weight: bold;
    }

    &--now {
      border: 1px dashed $IndyWorkPurpleNew !important;
    }
    &--hasActive,
    &--active {
      border: 1px solid $IndyWorkPurpleNew !important;
      background-color: transparent;
    }
  }

  &__navigation {
    background-color: transparent;

    & button:enabled:hover,
    & button:enabled:focus {
      background-color: transparent;
    }

    &__label {
      color: white;
      background-color: transparent;
      font-size: 20px;
      &:disabled {
        background-color: transparent !important;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__arrow {
      color: white;
      background-color: transparent;
      font-size: 20px;
    }

    &__prev-button {
      color: white;
      background-color: transparent;
    }
  }

  &__month-view {
    &__days__day {
      color: white;
      &--weekend {color: white;}
      &--neighboringMonth {color: gray;}
    }
    &__weekdays {
      color: $IndyWorkPurpleNew;
      margin-bottom: 10px;
      &__weekday abbr {
        text-decoration: none !important;
      }
    }
  }

  &__year-view__months__month {color: white;}
}

.DotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.dot {
  border-radius: 8px;
  width: 6px;
  height: 6px;
  margin: 1px;

  &-red {
    @extend .dot;
    background-color: $Error;
    border: 1px solid $Error;
  }

  &-green {
    @extend .dot;
    background-color: $IndyWorkGreen_d;
    border: 1px solid $IndyWorkGreen_d;
  }

  &-blue {
    @extend .dot;
    background-color: $IndyWorkBlue;
    border: 1px solid $IndyWorkBlue;
  }
}
