@import '../../../../sass/themes/colors';
@import '../../../../sass/scroll-bar';

.messageList {
  composes: scrollBar;
  width: 100%;
  flex: 1 0;
  overflow: auto;
}

.dancerMsgWrapper {
  width: 50%;
  margin: 0 auto 0 0;
}

.bizMsgWrapper {
  width: 50%;
  margin: 0 0 0 auto;
}

.message {
  position: relative;
  border: none;
  color: white;
  margin: 0 15px 0;
  padding: 5px 15px 5px;
  font-size: 1.05rem;
  text-align: right;
  background-color: $IndyWorkRust;

  &.dancer {
    text-align: left;
    background-color: $IndyWorkBlue_ddd;
  }

  &.first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 5px 15px 0;
    padding: 10px 15px 5px;
  }

  &.last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0 15px 5px;
    padding: 5px 15px 10px;
  }
}

%date {
  color: $IndyWorkGray_d;
  font-size: 0.85rem;
  margin: 0 15px;
}

.bizDate {
  @extend %date;
  text-align: right;
}

.dancerDate {
  @extend %date;
  text-align: left;
}

.warnMsg {
  flex: 1 0;
  color: $IndyWorkGray_d;
  font-size: 16px;
  text-align: center;
}

.errorMsg {
  color: $IndyWorkRed_ll;
}

.clearFix {
  float: left;
  clear: both;
}

.hidden {
  visibility: hidden;
}
