@import 'src/sass/themes/colors';

.senderInfo {
  flex: 0 1 180px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  color: $IndyWorkGray_dd;
  margin-right: 40px;

  div {
    font-weight: bold;
    &::first-letter {
      text-transform: capitalize;
    }
    color: $white;
  }

  p {
    font-size: 12px;
    margin: 0;
    span { font-weight: bold; }
  }
}
