@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';
@import 'src/sass/themes/colors';

.modalCourseCategory {
  composes: modalWrapper;
  width: 500px;  
  padding: 40px;

  form {
    margin-top: 20px;
  }
}

.actionRow {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  button {
    width: 100px;
  }
}

.error {
  color: $Error;
  min-height:1.5rem
}
