@import 'src/sass/themes/colors';

.asideTabWrapper {
  color: $white;
}

.tab {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px $IndyWorkPaneDivider;
  justify-content: space-between;
  cursor: pointer;

  h2 {
    font-weight: normal;
    margin: 0;
  }

  svg {
    font-size: 24px;
    align-self: center;
    margin-right: 20px;
  }
}
