@import 'src/sass/themes/colors';

.itemWrapper {
  padding-top: 20px;  
  border-top: solid 1px $IndyWorkPaneDivider;
  margin-top: 20px;
}

.itemLabel {
  font-size: 14px;
  margin-bottom: 5px;
  span {
    color: $IndyWorkPurple_ll;
  }
}

.item {
  border: 0;
  padding: 0;
  width: 100%;
  outline: none;  
  cursor: pointer;  
  background-color: transparent;
  color: $white;  

  .title {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  .message {
    margin-bottom: 10px;
    text-align: left;
    color: $IndyWorkGray_dd;
  }
}
