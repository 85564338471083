@import 'src/sass/themes/colors';

.closeBtn {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  color: $IndyWorkGray_l;
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 10;

  svg {
    color: $IndyWorkWhite_d;
    font-size: 24px;

    &:hover {
      color: $IndyWorkLightPurple;
    }
  }
}
