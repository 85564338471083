@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';

.listWrapper {
  height: calc(100% - 220px);
}

.groupNotificationTableHeader {
  margin-top: 40px;
  display: flex;
  color: $IndyWorkPurpleNew;
  font-size: 14px;
  &::first-letter {
    text-transform: capitalize;
  }
  margin-bottom: 10px;

  div:nth-child(1) {
    flex: 0 1 227px;
    margin-left: 180px;
  }
  div:nth-child(2) { flex: 1 0;}
  div:nth-child(3) { flex: 0 1 150px;}
}

.loadingRow {
  height: 115px;
  text-align: center;
}
