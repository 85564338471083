@import 'src/sass/themes/colors';

%chatItem {
  display: flex;
  align-items:center;
  cursor: pointer;
  padding: 5px 10px 5px 5px;
  margin: 0 1px 0 0;
  border: 1px solid transparent;
  border-radius: 5px;
  &:hover {
    border: 1px solid $IndyWorkPurpleNew;
  }
}

.chatItemActive {
  @extend %chatItem;
  background-color: $IndyWorkNavBar;
  border: 1px solid $IndyWorkPurpleNew;
}

.chatItemUnActive {
  @extend %chatItem;
  background-color: transparent;
}

.photoArea {
  flex: 0 1 60px;
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 10px;

  img {
    width: 60px;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
    &.greyOut {
      filter: grayscale(1.0);
    }
    &.inGroup { border:1px solid $IndyWorkGreen} 

  }

  div[class*="rejectedOverlay"] {
    border-radius: 50%;
  }

  span {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    display: flex;
    color: $white;
    background-color: $WorkerPink;
    justify-content: center;
    align-items: center;
    line-height: 16px;
  }
}
.detailArea {
  flex: 1 0;
  display: grid;
  grid-template-areas:
          'name time'
          'lastMsg lastMsg'
          'discipline discipline';
  grid-template-columns: 1fr 100px;
  grid-template-rows: 25px 20px;

  h4 {
    grid-area: name;
    font-size: 14px;
    color: $white;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  span {
    grid-area: time;
    font-size: 12px;
    color: $IndyWorkGray_d;
    text-align: right;
  }

  .lastMsg {
    margin: 0;
    grid-area: lastMsg;
    overflow: hidden;
    font-size: 14px;
    color: $IndyWorkOffWhite;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .discipline {
    margin: 0;
    overflow: hidden;
    font-size: 12px;
    color: $IndyWorkOffWhite;
    text-overflow: ellipsis;
    white-space: nowrap;
    grid-area: discipline;
    align-self: flex-end;
    .blocked { color: $standByOrange }
    .suspended { color: $IndyWorkRed; }   
  }
}
