@import 'src/sass/themes/colors';

.miniCalLegend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ul {
    list-style-type: none;
    margin: 0.5em 0 0 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0.25em 0;

      span {
        color: white;
        font-size: 1.15rem;
        margin-left: 1em;
      }
    }
  }
}

%box {
  width: 20px;
  height: 20px;
  border-color: $IndyWorkPurpleNew;
  border-width: 1px;
  background-color: transparent;
}

.boxDashed {
  @extend %box;
  border-style: dashed;
}

.boxSolid {
  @extend %box;
  border-style: solid;
}


%legendColor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.colorRed {
  @extend %legendColor;
  background-color: #e76464;
  border: 1px solid #e76464;
}

.colorGreen {
  @extend %legendColor;
  background-color: #81bf6c;
  border: 1px solid #81bf6c;
}

.colorBlue {
  @extend %legendColor;
  background-color: #439ace;
  border: 1px solid #439ace;
}
