@import 'src/sass/themes/colors';

.inputField {
  flex: 1 0;
  transition: padding 0.4s;
  background-color: $IndyWorkNavBar;
  border: 1px solid transparent;
  border-radius: 2px;
  width: 100%;
  padding: 0.75rem 0.5rem;

  color: $white;

  :hover,
  :focus {
    border: 1px solid $IndyWorkPurpleNew;
    color: $IndyWorkWhite_d;
  }

  :disabled {
    background: transparent;
    border: none;
    font-size: 18px;
    margin-left: 0;
    padding: 0;
    font-weight: normal;
    resize: none;
    overflow-y: scroll;

    :hover, :focus {
      border: none;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      font-style: italic !important;
      opacity: 1; /* Firefox */
    }
  }
}
