@import 'src/sass/themes/colors';

.carouselArea {
  width: 100%;
  position: relative;
  flex: 0 1 450px;

  :global(.image-gallery-thumbnail) {
    width: 75px;
  }
}

.carouselImgContainer {
  height: auto;
  margin: auto;
  width: 460px;

  img { width: 100%; }
}

.blocked {
  filter: grayscale(1);
}

.inGroup {
  border:1px solid $IndyWorkGreen;
}
.textOutline {
  font-size: 16px;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.icon {
  font-size: 24px;
  font-weight: bold;
}

.iconOutline path {
  stroke: black;
  stroke-width: 40px;
}
