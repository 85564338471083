@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';

.groupNotification {
  display: flex;
  padding: 16px 40px 16px 0;
  align-items: flex-start;
  background-color: transparent;
  border-bottom: solid 1px $IndyWorkPaneDivider;
  &.selected {
    border-bottom: solid 1px $IndyWorkPurple_dd;
    background-color: $IndyWorkPaneDivider;
  }
}

.selectionBtn {
  flex: 0 1 140px;
  display: flex;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin-right: 40px;
  padding: 0 0 0 20px;

  label { margin-right: 20px; }
  img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
  }
}

.notificationData {
  flex: 1;
  display: flex;
  font-size: 16px;
}

.deleteBtn {
  composes: invertedBtnRed;
  align-self: flex-start;
  flex: 0 1 105px;
  margin-left: 40px;
  padding: 8px 6px;
  margin-top: 30px;
}
