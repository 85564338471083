@import 'src/sass/themes/colors';
.imageGrid {
    width: 100%;
    height: 100%;
    cursor: grab;
    position: relative;
    input {
        display: none;
    }
    &.dragging {
        opacity: 0.6;
    }
    &:hover .trashIcon {
        opacity: 1;
    }
    &.isRound {
        height: 130px;
        width: 130px;
        border-radius: 100%;
    }
    &.active {
        background-color: $IndyWorkPurpleNew;
    }
}

.gridImg {
    border-radius: 5px;
    height: 130px;
    width: 130px;
    pointer-events: none;
    user-select: none;
    &.isRound {
        border-radius: 100%;
    }
}

.trashIcon {
    opacity: 0;
    height: 25px;
    width: 25px;
    right: 5px;
    bottom: 5px;
}