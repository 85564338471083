@import 'src/sass/themes/colors';
.imgGalleryWrapper {
    grid-area: imgGrid;
    position: relative;
    height: 150px;
    background-color: $ScrollBar;
    img {
        height: 130px;
        width: 130px;
        cursor: grab;
        border-radius: 5px;
        pointer-events: none;
    }
    &.disabled {
        cursor: default !important;
        img {
            cursor: default;
        }
    }
    .galleryGridWrapper:nth-child(1) .galleryGridItem {
        padding-left: 10px;
    }
    .galleryGridWrapper:nth-child(6) .galleryGridItem {
        padding-right: 10px;
    }
}

.galleryGridItem {
    padding: 10px 5px;
}

.gridItem {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

// .gridItemContent {
//     width: 130px;
//     height: 130px;
//     box-sizing: border-box;
//     background: #08e;
//     display: flex;
//     justify-content: center;
//     color: white;
//     font-family: Arial, Helvetica, sans-serif;
//     align-items: center;
//     border-radius: 50%;
// }