@import 'src/sass/themes/colors';

.radioSpan {
  background-color: transparent;
  border: 1px solid $IndyWorkPurpleNew;
  border-radius: 50%;
  padding: 6px;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;

  span {
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: inherit;
    height: 10px;
    width: 10px;
    background-color: $IndyWorkPurpleNew;
  }
}
