@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';

.createGroupModal {
  composes: modalWrapper;
  max-height: 100vh;
  min-width: 300px;
  padding: 20px;
  width: 500px;  
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
