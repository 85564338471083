@import 'src/sass/themes/colors';

%button {
  cursor: pointer;
  transition: all 0.4s ease;
  &::first-letter {
    text-transform: capitalize;
  }
  padding: 8px 16px;
  border-radius: 2px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  align-self: center;
  text-align: center;
  &:disabled { cursor: not-allowed; }
}


@mixin fillBtn($primary, $hover, $disabled) {
  color: $IndyWorkWhite_d;
  background-color: $primary;
  border-color: $primary;

  &:hover:not(:disabled) {
    color: $white;
    border-color: $hover;
    background-color: $hover;
  }

  &:disabled {
    color: $IndyWorkGray_dd;
    background-color: $disabled;
    border-color: $disabled;
  }
}

@mixin invertBtn($primary, $hover, $disabled) {
  color: $primary;
  border-color: $primary;
  background-color: transparent;

  &:hover:not(:disabled) {
    color: $white;
    border-color: $hover;
    background-color: $hover;
  }

  &:disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.invertedBtnOrange {
  @extend %button;
  @include invertBtn($IndyWorkOrange_l, $IndyWorkOrange_l_50, $IndyWorkOrange_l);
}

.invertedBtnYellow {
  @extend %button;
  @include invertBtn($IndyWorkYellow, $IndyWorkYellow_ll_50, $IndyWorkYellow_ll);
}

.invertedBtnBlue {
  @extend %button;
  @include invertBtn($IndyWorkPurpleNew, $activeBlue, $WebBusinessBlue_d);
}

.filledBtnBlue {
  @extend %button;
  @include fillBtn($WebBusinessBlue_l, $activeBlue, $WebBusinessBlue_d);
}

.invertedBtnGreen {
  @extend %button;
  @include invertBtn($deepGreen, $IndyWorkGreen_ll, $IndyWorkGreen_ll_50);
}

.filledBtnGreen {
  @extend %button;
  @include fillBtn($deepGreen, $IndyWorkGreen_ll, $IndyWorkGreen_ll_50);
}

.invertedBtnRed {
  @extend %button;
  @include invertBtn($IndyWorkRed_l, $hoverRed, $IndyWorkRust);
}

.filledBtnRed {
  @extend %button;
  @include fillBtn($IndyWorkRed_ll, $hoverRed, $IndyWorkRust);
}

.invertedBtnPink {
  @extend %button;
  @include invertBtn($WorkerPink_l, $WorkerPink, $WorkerPink_d);
}

.filledBtnPink {
  @extend %button;
  @include fillBtn($WorkerPink_l, $WorkerPink, $WorkerPink_d);
}

