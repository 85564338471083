@import 'src/sass/scroll-bar';
@import 'src/sass/buttons/button.module';

.actionArea {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button:first-child {
    margin-right: 20px;
  }
}

.dancerList {
  composes: scrollBar;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 20px 0;
  overflow-x: hidden;
  max-height: 300px;

  &::after {
    content: '';
    flex: auto
  }
}

