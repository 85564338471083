@import 'src/sass/buttons/button.module';

.confirmationModal {
  button {
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    &:last-child { margin-bottom: 0; }
  }
}
