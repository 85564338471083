@import '../../../../../sass/themes/colors';
@import '../../../../../sass/error-styles';

.acceptedItem {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  min-height: 100px;
}

.inGroup {
  border:1px solid $IndyWorkGreen;
}

