@import 'src/sass/buttons/button.module';

.preApprovalForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.calendarArea {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.actionArea {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button:first-child {
    margin-right: 20px;
  }
}
