@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';

.notificationList {
  composes: scrollBar;
  margin: 0 0 20px;
  padding: 0;
}

.notificationWrapper {
  color: $white;
  margin-bottom: 20px;
}

.notificationDate {
  color: $IndyWorkPurpleNew;
  margin-bottom: 20px;
  &::first-letter {
    text-transform: capitalize;
  }
  font-size: 18px;
  font-weight: bold;
}
