@import 'themes/colors';
@import 'themes/fonts';
@import 'themes/modal-constants';

.modalWrapper {
  position: fixed;
  max-height: 100vh;  
  z-index: 4;  
  background-color: $modalBackground;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.4);
}

.modalHeading {
  color: $modalHeadingColor;
  font-family: $modalHeadingFont;
  font-size: $modalHeadingSize;
  font-weight: bold;
  margin: 0 0 20px;
  align-self: flex-start;
}

.modalLabel {
  color: $modalLabelColor;
  font-family: $modalLabelFontFamily;
  font-size: $modalLabelFontSize;
  &::first-letter {
    text-transform: capitalize;
  }
  &.disabled { color: $modalLabelColorDisabled }
  &.error { color: $modalLabelColorError; }
}

.modalInput {
  width: 100%;
  background-color: $modalInputBg;
  font-family: $modalInputFont;
  color: $modalInputColor;
  border: 1px solid $modalInputBorder;
  font-size: $modalInputSize;
  border-radius: $modalInputBorderRadius;

  &:focus,
  &:active,
  &:hover {
    color: $modalInputFocusColor;
  }

  &:disabled {
    color: $modalInputColorDisabled;
    background-color: $modalInputBgDisabled;
    border-color: $modalInputBorderDisabled;
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid $modalInputBorderError;
  }
}
