%bookingModalForm {
  display: grid;
  grid-template-columns: 115px 115px 115px 115px 115px 115px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.bookingCreateForm{
  @extend %bookingModalForm;
  grid-template-areas:
          'date date starts ends checkOut checkOut'
          'booking_type booking_type repeat repeat recurring recurring'
          'eventGrid eventGrid eventGrid eventGrid eventGrid eventGrid'
          'promoGrid promoGrid promoGrid promoGrid promoGrid promoGrid'
          'slots hardCap preapproved preapproved notify notify'
          'ActionArea ActionArea ActionArea ActionArea ActionArea ActionArea';
}

.bookingUpdateEventModal {
  @extend %bookingModalForm;
  grid-template-areas:
          'date date starts ends booking_type booking_type'
          'slots hardCap checkOut checkOut  preapproved preapproved'
          'eventGrid eventGrid eventGrid eventGrid eventGrid eventGrid'
          'occurrence occurrence ActionArea ActionArea ActionArea ActionArea';
}

.bookingUpdatePromoModal {
  @extend %bookingModalForm;
  grid-template-areas:
          'date date starts ends booking_type booking_type'
          'slots hardCap checkOut checkOut  preapproved preapproved'
          'promoGrid promoGrid promoGrid promoGrid promoGrid promoGrid'
          'occurrence occurrence ActionArea ActionArea ActionArea ActionArea';
}

.bookingUpdateModal {
  @extend %bookingModalForm;
  grid-template-areas:
          'date date starts ends booking_type booking_type'
          'slots hardCap checkOut checkOut  preapproved preapproved'
          'occurrence occurrence ActionArea ActionArea ActionArea ActionArea';
}

