@import 'src/sass/themes/colors';

.chatMsgArea {
  flex: 1 0;
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  border-top: solid 1px $ScrollBarThumb;
  border-left: solid 1px $ScrollBarThumb;  
  justify-content: center;
}

.warnMsg {
  color: $IndyWorkGray_d;
  font-size: 21px;
  text-align: center;
}

.errMsg {
  color: $IndyWorkGray_d;
  font-size: 21px;
  text-align: center;
  flex: 1 0;
}
