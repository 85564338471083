.imageWrapper {
  position:relative;
}
.rejectedOverlay {
  position:absolute !important;
  top:0 !important;
  left:0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255,0,0,0.5) !important;
}