@import '../../../../sass/themes/colors';
@import '../../../../sass/error-styles';
@import '../../../../sass/scroll-bar';

.emptyList {
  color: $white;
  text-align: center;
}

.inviteList {
  composes: scrollBar;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1 0;
}
