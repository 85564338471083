@import 'src/sass/layout-styles.module';
@import 'src/sass/buttons/button.module';
@import 'src/sass/heading';

.userPageWrapper {
  composes: pageWrapper;

  header { display: flex; }
  h2 { margin-bottom: 20px; }
  label { font-size: 14px; }
}

.formArea {
  display: flex;
  margin-top: 40px;
}

.imgArea {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-right: 40px;
  position: relative;

  h2 {
    text-align: center;
    & + div {
      margin: 0 auto;
    }
    & input + div {
      height: 220px;
    }
  }

  img:hover svg {
    opacity: 1;
  }

  svg {
    height: 45px;
    width: 45px;
    right: 10px;
    bottom: 90px;
    padding: 10px;
  }
}

.textFields {
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  label { margin-bottom: 10px; }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & div {
    width: 250px;

    &:first-child { margin-right: 30px; }
  }
}


.permissions {
  &::first-letter {
    text-transform: capitalize;
  }
  width: 240px;
  label { margin-bottom: 5px; }
}

.passwordResetButton {
  margin-left: 10px;
}
