@import 'src/sass/themes/colors';

.mainTemplate {
  background-image: url(../../assets/splash-template.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  text-align: center;
  color: $white;
  padding-top: 4rem;

  img {
    margin-bottom: 20px;
    width: 235px;
    padding-top: 10%;
  }

  footer {
    padding: 35px 0;
    flex: 1 0;
    display: flex;
    align-items: flex-end;

    a {
      color: $IndyWorkBlack_l;
      &:nth-child(2) { margin: 0 4rem; }
    }
  }
}
