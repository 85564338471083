@import 'src/sass/heading';
@import 'src/sass/buttons/button.module';

.coursesHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.coursesHeaderLeft {
  flex: 0 1 450px;
  display: flex;
  align-items: flex-start;

  button {
    margin-right: 10px;    
  }
}

.coursesHeaderRight {
  display: flex;
  flex: 1 0;

  .sideLabel {
    margin-left: auto;
    margin-right: 20px;
    align-self: center;
    margin-bottom: 0;
  }

  div:nth-child(2) {
    width: 200px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    button {
      border-radius: 20px;
      padding: 10px;
    }
  }

  div:nth-child(3) {
    width: 200px;
  }
}
