@import 'src/sass/themes/colors';


.textWrapper {
  margin-bottom: 10px;
  padding-bottom: 10px;

  span {
    color: $IndyWorkPurpleNew;
    display: inline-block;    
  }

  &:last-child {    
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.msgTitle {
  color: $white;
  font-weight: bold;
  margin-bottom:0.25rem;
}

.msgWrapper {
  display: block;
  flex: 1 0;
  padding-left: 10px;
  font-size: 14px;
  word-break: break-all;
}

.target {
  color: $IndyWorkPurpleNew;
  a {
    color: $IndyWorkGray_dd;;
    text-decoration: underline;
    &:hover {
      color: $IndyWorkPurple;
      text-decoration: none;
    }
  }
}

