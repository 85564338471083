@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';
@import 'src/sass/error-styles';

.verificationForm {
  color: $IndyWorkWhite_d;
  width: 220px;
  height: auto;
  align-self: stretch;
  padding: 70px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  button {
    display: block;
    margin: 0 auto;
    width: 120px;

    &:last-child {
      margin-top: 10px;
    }
  }
}

.dancerInfo {
  margin-bottom: 20px;

  h3 { margin: 0 }
  p {
    margin: 0;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    span { font-weight: bold; }
  }
}

.reasonList {
  list-style: none;
  padding: 0;
  flex: 1 0;
  margin-bottom: 1rem;
  margin-top: 1rem;

  label { width: 100%; }

  li {
    margin-bottom: 5px;
  }
}

.submitBtn {
  composes: filledBtnGreen;
  flex: 0 1 40px;
}

