@import 'src/sass/buttons/button.module';

.groupSubHeaderWrapper {
  grid-area: counts;
  display: flex;
}

.groupSubHeaderActionArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: center;

  button {
    min-width: 90px;
    margin-left: 10px;
  }
}
