@import 'src/sass/buttons/button.module';
@import 'src/sass/error-styles';
@import 'src/sass/heading';
@import 'src/sass/layout-styles.module';
@import 'src/sass/scroll-bar';

.header {
  display: flex;
  margin-bottom: 40px;
  div:last-child {
    width: 320px;
    margin-left: auto;
  }
}

.createGroupBtn {
  composes: invertedBtnGreen;
  margin: 0 20px 0 0;
}

.changeOrderBtn {
  composes: invertedBtnBlue;
  margin: 0 auto 0 0;
}
