@import 'src/sass/themes/colors';

.menuWrapper {
  position: absolute;
  top: 20px;
  right: 0;
  color: $white;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 5;

  button {
    background-color: $IndyWorkPurple_d;
    transition: all .2s;
    cursor: pointer;
    color: $white;
    border: 0;
    text-align: left;
    padding: 12px;
    width: 100%;
    border-bottom: 1px solid $IndyWorkNavBar;
    line-height: normal;
    font-size: 14px;

    &:hover {      
      background-color: $IndyWorkPurple_l;
    }

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
}
