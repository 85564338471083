@import 'src/sass/themes/colors';

.emptyImg {
  border: 1px dashed $IndyWorkPurple_d;
  color: $IndyWorkWhite_d;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.round {
    border-radius: 100%;
  }
}

