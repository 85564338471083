@import 'src/sass/buttons/button.module';
@import 'src/sass/modal';

.modal {
  composes: modalWrapper;
  width: 500px;
  min-width: 300px;
  max-height: 100vh;
  padding: 20px;
}

.xs {
  width: 300px;
}

.sm {
  width: 540px;
}

.md {
  width: 720,
}

.lg {
  width:960px
}

.xl {
  width:1140;
}

.full {
  width: 95%;
}