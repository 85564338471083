@import 'src/sass/themes/modal-constants';
@import 'src/sass/themes/colors';

.dancerActionMenuWrapper {
  position: relative;
}

.dancerActionMenuBtn {
  outline: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    color: $IndyWorkGray_d;
    font-size: 14px;
    margin-top: 4px;
  }
}

.dancerActionSelect {
  background-color: $modalInputBg;
  color: $modalInputFocusColor;
  border: 1px solid $modalInputBorder;
  font-size: $modalInputSize;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.25em 0.5em 0.25em 0.35em;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  height: 35px;
  width: 100%;
}
