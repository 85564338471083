@import 'src/sass/buttons/button.module';
@import 'src/sass/themes/colors';

.inviteListItem {
  width: 100%;
  background-color: $IndyWorkBlue_dddd;

  &.active {
    background-color: transparent;
  }
}

.creatorImg {
  border-radius: 4px;
  max-width: 130px;
  max-height: 130px;
  height: 129px;
  width: 125px;
  margin-right: 40px;
  filter: none;
  &.inactive {filter: grayscale(1.0);}
}

.invite {
  border-top: 1px solid $IndyWorkNavBar;
  color: $white;
  border-radius: 3px;
  transition: 0.3s;
  display: flex;
  height: 150px;
  padding: 10px 0;
}

.dataColumn {
  text-align: center;
  display: flex;
  flex: 1 0;
}

.row {
  display: flex;
  align-items: center;
}

.inviteLabel {
  color: $IndyWorkGray_d;
  margin-right: 5px;
}

.code {
  color: $white;
  font-weight: bold;
  font-size: 18px;
}

.description {
  margin: 0;
  text-align: left;
  overflow-y: auto;
  max-height: 60px;
}

.actionCol {
  display: flex;
  flex-direction: column;
  flex: 0 1 180px;
  justify-content: center;
  button:first-child {
    margin-bottom: 10px;
  }
}

.actionBtn {
  composes: invertedBtnBlue;
  width: 180px;
  position: relative;

  span { margin-left: 5px; }
}

.bold {
  margin: 0 5px;
  color: $IndyWorkGray_d;
  span { color: $IndyWorkPurpleNew; }
}

%status {
  font-weight: bold;
  margin-right: 5px;
}

.statusActive {
  @extend %status;
  color: $IndyWorkGreen;
}

.statusInactive {
  @extend %status;
  color: $IndyWorkRed;
}
