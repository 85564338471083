@import '../../../../sass/themes/fonts';
@import '../../../../sass/themes/colors';

.formLabel {
  font-family: $Roboto;
  font-size: 12px;
  color: $white;
  &::first-letter {
    text-transform: capitalize;
  }

  &.error {
    color: $IndyWorkRed_l;
  }

  &.disabled {
    color: $IndyWorkPurple;
  }
}

