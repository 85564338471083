@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.imgField {
  flex: 1 0;
  text-align: center;
  border-radius: 5px;

  input[type="file"] {
    display: none;
  }

  &:hover .trashIcon {
    opacity: 1;
  }

  &.isRound {
    border-radius: 50%;
  }
}

.imgWrapper {
  pointer-events: none;
  object-fit: cover;
  object-position: center right;
  border-radius: 5px;

  &.opaque {
    opacity: 0.75;
  }

  &.isRound {
    border-radius: 50%;
  }

  &:hover + svg {
    opacity: 1;
  }

  &:hover > svg {
    opacity: 1;
  }
}

.trashIcon {
  opacity: 0;
  height: 25px;
  width: 25px;
  right: 5px;
  bottom: 5px;
}
