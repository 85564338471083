@import 'src/sass/themes/colors';

.dropDownSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 10px;
  justify-content: flex-start;
}

.dropBox {
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: $IndyWorkNavBar;
  cursor: pointer;
  position: relative;

  ul {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style-type: none;
    top: 30px;
    left: 0;
    width: 160px;
    border-radius: 4px;
    background-color: $IndyWorkNavBar;
    z-index: 15;

    & > li {
      color: $white;
      cursor: pointer;
      padding: 0.75em 1em;
      font-size: 1.1em;
      transition: font-size 0.2s;
      border-radius: 4px;

      & a {
        color: $white;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $IndyWorkNavBar;
      }

      &:hover {
        background-color: $IndyWorkPurple_l;
      }
    }
  }
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $IndyWorkWhite_d;
  margin-left: 5px;
}
