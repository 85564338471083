@import 'src/sass/heading';
@import 'src/sass/layout-styles.module';
@import 'src/sass/error-styles';
@import 'src/sass/buttons/button.module';

.formWrapper {
  height: calc(100% - 200px)
}

.clubForm {
  margin-bottom: 40px;
  header {
    display: flex;
    margin-bottom: 40px;
    align-items: center;    

    button {
      align-self: flex-start;
      width: 110px;
      height: 40px;
      font-size: 14px;      
    }
    .actionRow {

      button:not(:first-child) {
        margin-left:0.5em;
      }
    }
  }
}

.clubActionRow {
  display: flex;
  justify-content: flex-start;
  margin: 60px 0 70px;

  a {
    text-decoration: none;
    margin: 0;
    padding: 10px 16px;
  }

  a,
  button {
    width: 110px;
    height: 40px;
    font-size: 14px;
  }

  .invertedBtnRed {
    margin-right: 20px;
  }
}
