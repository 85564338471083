@import 'src/sass/themes/colors';

.preApprovedBookingItemBtn {
  width: 100%;
  cursor: pointer;
  padding: 10px 5px 10px 5px;
  border: 0;
  border-bottom: 1px solid $IndyWorkPurple_d;
  background-color: transparent;
  color: $white;
  text-align: left;
  outline: none;

  &.disabled {
    cursor: default;
    color: $IndyWorkGray_d;

    &:hover {
      background-color: initial;
    }
  }

  &:hover {
    background-color: $IndyWorkPurple_d;
  }
}

.topArea {
  color: $white;
  display: flex;
  justify-content: space-between;
  &.disabled {
    color: $IndyWorkGray_d;
  }
}


.extra {
  font-size: 10px;
}

