@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';

.notificationModal {
  composes: modalWrapper;
  width: 700px;;
  min-width: 300px;
  padding: 20px;
}

.fieldContainer {
  width: 100%;

  &:nth-child(2) {
    margin-left: 20px;
  }
}

.grid {
  display: flex;
  margin-bottom: 20px;
}

.inputField {
  margin-bottom: 20px;
}
