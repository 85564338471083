@import 'src/sass/themes/colors';

.logoWrapper {
  position: relative;
  min-height: 110px;
  max-height: 110px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 160px;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  svg {
    color: white;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 3;

    &:hover {
      & * {
        color: $IndyWorkLightPurple;
      }
    }
  }
}

.discoBall {
  width: 160px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.clubName {
  width: 160px;
  position: absolute;
  top: 2px;
  z-index: 1;
}
