.bookingEventImg {
  grid-area: eventImage;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  div {
    width: 100%;
    height: 100%;
    margin: 0 auto 0;
  }
}

