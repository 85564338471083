@import 'src/sass/themes/colors';

.container {    
  z-index: 9;
  position: absolute;  
}

.button {
  background-color: $IndyWorkNavBar;
  border-radius: 20px;
}

.select {  
  display: flex;
  flex-direction: column;
  background-color: $IndyWorkNavBar;
  padding: 1px;
  border-radius: 2px;
}

.option {
  display: flex;
  flex-direction: row;
  gap:4px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 10px;
  min-width: 125px;
  margin: 2px;
  &:hover {
    background-color: $IndyWorkGray_d;
    cursor: pointer;
  }
  &.disabled {
    color: gray;
    &:hover {
      background-color: $IndyWorkNavBar;
      cursor: default;
    }
  }
}