@import 'src/sass/themes/colors';

.msgReceiver {
  display: flex;
  justify-content: space-between;
  background-color: $IndyWorkNavBar;
  flex: 0 1 75px;

  .imgDivWrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
    margin: 5px 10px;
    pointer-events: none;

    &.suspended {
      filter: grayscale(1.0);
    }
    &.inGroup {
      border:1px solid $IndyWorkGreen;
    }
  }
  div[class*="rejectedOverlay"]{
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 10px;    
  }
  div:nth-child(3) {
    flex: 0 1 20px;
    margin-right: 20px;
    align-self: center;
  }
}

.msgReceiverDetails {
  flex: 1 0;
  color: white;
  align-self: center;

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;  
    display: flex;
    line-height:1em;
  }

  p {
    font-size: 12px;
    margin: 0;
    
  }


  .discipline {    
    font-size: 12px;
    .blocked { color: $standByOrange }
    .suspended { color: $IndyWorkRed; }
    span {
      margin-left:1em;
    }
  }
}
