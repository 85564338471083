@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';
@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';

.addToGroupForm {
  margin: 20px;
  align-items: center;
}

.dancerList {
  composes: scrollBar;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 20px 0;
  overflow-x: hidden;
  max-height: 400px;

  &::after {
    content: "";
    flex: auto
  }
}

.actionArea {
  justify-content: flex-end;
  display: flex;

  button:last-child {
    margin-left: 20px;
  }
}

.groupName {
  margin-bottom: 10px;
}
