@import 'src/sass/themes/modal-constants';
@import './booking-label-styles.modules';


.bookingDuplicateField {
  grid-area: repeat;

  div {
    display: flex;
    background-color: $modalInputBg;
    color: $modalInputFocusColor;
    border: solid 1px $modalInputBorder;
    justify-content: space-between;
    border-radius: 2px;
    button:last-child { border-right: 0; }
  }
}

.duplicateBtn {
  background-color: $modalBtnBg;
  cursor: pointer;
  color: $IndyWorkGray_dd;
  text-align: center;
  flex: 1 0;
  border: 0;
  border-right: 1px solid $modalInputBorderDisabled;
  padding: 13px 0 12px;

  &:hover {
    color: $modalBtnColorHover;
    background-color: $modalBtnBgHover;
  }

  &.active {
    color: $modalInputFocusColor;
    background-color: $modalBtnActive;

    &:hover {
      background-color: $modalBtnActiveHover;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: $IndyWorkGray_dd;
    border-color: $ScrollBar;
    background-color: $ScrollBar;
    border-right: 1px solid $IndyWorkPaneDivider;
  }
}
