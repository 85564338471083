@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';

.chatList {
  composes: scrollBar;
  flex: 1 0;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.warningMsg {
  color: $IndyWorkGray_d;
  font-size: 1em;
  text-align: center;
  flex: 1;
}

.errorMsg {
  color: $IndyWorkRed_ll;
}

.chatUserList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hidden{
  visibility: hidden;
}

.spinnerWrapper{
  width: 100%;
  height: 50px;
}

.spinner{
  width: 25px !important;
  height: 25px !important;
}
