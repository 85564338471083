@import 'src/sass/themes/colors';
.trash {
    transition: 300ms ease all;
    height: 25px;
    width: 25px;
    color: white;
    cursor: pointer;
    padding: 4px;
    position: absolute;
    border-radius: 100%;
    background-color: $IndyWorkRed;
}