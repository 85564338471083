@import 'src/sass/overlay.module';
@import 'src/sass/error-styles';

.calGrid {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.calWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.calArea {
  flex: 1;
}


.miniCal {
  margin-left: 80px;
}
