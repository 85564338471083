@import 'src/sass/buttons/button.module';
@import 'src/sass/modal';

.modal {
  composes: modalWrapper;
  width: 640px;
  max-width:90%;
  max-height: 90vh;
  padding: 20px;
}
