@import 'src/sass/themes/colors';

.checkboxSpanLabel {
  height: 20px;
  width: 20px;
  margin: 0;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  border: solid 1px $IndyWorkCB_gray;

  span {
    background-color: $IndyWorkWhite_d;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    &.active {
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 4px;
        top: 4px;
        width: 12px;
        height: 12px;
        border-radius: 1px;
        background-color: $IndyWorkPurple_l;
      }
    }
  }
}

.formCheckbox {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin: 0;
  background-color: $uncheckedCheckbox;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $IndyWorkGray_d;
  }

  &.active {
    background-color: $IndyWorkPurpleNew;
  }

  &.disabled {
    background-color: $uncheckedCheckbox;
    &:hover {
      cursor:default;
    }
  }

  span {
    position: absolute;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
