@import 'src/sass/overlay.module';

.miniCalendar {
  position: relative;

  img {
    position: absolute;
    width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loadingOverlay {
  composes: darkOverlay;
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.65) 36%,rgba(0,0,0,0.56) 44%,rgba(0,0,0,0) 97%,rgba(0,0,0,0) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
