@import 'src/sass/buttons/button.module';
@import 'src/sass/modal';
@import 'src/sass/inputs/text-input.module';

.suspensionModal {
  composes: modalWrapper;
  width: 500px;
  min-width: 300px;
  max-height: 100vh;
  padding: 20px;
}

.suspensionError {
  height: 16px;
  color: $Error;
  font-size: 10px;
  text-align: left;
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.suspensionLabel {
  font-weight: normal;
  font-size: 11px;
  &::first-letter {
    text-transform: capitalize;
  }
  font-family: sans-serif;
  line-height: 16px;
  letter-spacing: 0;
  align-self: flex-start;
  color: $IndyWorkWhite_d;
  margin: 0  0 10px 0;
}
