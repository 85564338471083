@import 'src/sass/themes/colors';
@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';
@import 'src/sass/table-styles';

.listWrapper {
  height: calc(100% - 200px);
}

.notificationTableHeader {
  composes: tableHeader;
  margin-top: 40px;

  div:nth-child(1) {
    flex: 0 1 239px;
    margin-left: 129px;
  }
  div:nth-child(2) { flex: 1 0; }
}

.errorHeading {
  composes: errorMessage;
  padding-top: 100px;
}

.loadMorebtnWrapper{
  display: flex;
  justify-content: center;
  margin: 1rem;
}
