@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';

.container {
  z-index: 9;
  position: relative;  

  input {
    border: none;  
    border-radius: 2px;
    background-color: transparent;
    color: $IndyWorkWhite_d;
    font-size: 12px;
    align-self: center;
    background-color: $IndyWorkNavBar;
    padding: 12px 8px;
  }
}


.select {
  display: flex;
  flex-direction: column;
  background-color: $IndyWorkNavBar;
  padding: 1px;
  border-radius: 2px;
  margin-top: 5px;
}

.option {
  display: flex;
  flex-direction: row;
  gap:4px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 10px;
  min-width: 125px;
  margin: 2px;
  &.active {
    background-color: $IndyWorkGray_d;
  }
  &:hover {
    cursor: pointer;
  }
}
