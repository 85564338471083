@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';

.dancerModalGrid {
  display: flex;
  height: 100%;
}

.infoArea {
  flex: 1 0;
  padding: 40px 30px;

  select {
    margin-bottom: 20px;
  }

  article {
    margin-top: 40px;
    overflow-y: auto;

    label {
      color: $IndyWorkPurpleNew;
      font-size: 12px;
      font-weight: normal;
      &::first-letter {
        text-transform: capitalize;
      }
      margin-bottom: 6px;
    }

    div {
      font-family: $Lato;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.licenseArea {
  composes: scrollBar;
  display: flex;
  flex-wrap: wrap;
  max-height: 140px;

  .license {
    flex: 0 1 50%;
    margin-bottom: 40px;

    .bold {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.nameArea {
  font-family: $Roboto;
  padding: 0;
  margin-bottom: 40px;
  h3 {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    text-transform: capitalize;
    color: $white;
    margin: 0 0 5px;
  }

  h4 {
    font-size: 16px;
    color: $IndyWorkPurpleNew;
    margin: 0 0 5px;
  }

  h5 {
    font-size: 16px;
    color: $IndyWorkGray_d;
    margin: 0;
  }
}

.bio {
  composes: scrollBar;
  display: block;
  height: auto;
  color: white;
  line-height: 1.4em;
  font-size: 14px;
}

.styleList {
  composes: scrollBar;
  font-size: 14px;
  color: $IndyWorkWhite_d;
  display: flex;
  flex-wrap: wrap;
  word-spacing: 10px;
}

.dropdownMenu {      
  bottom: 5px;
  right: 0px;
  padding: 6px 12px;
}
.dropdownMenuButton {
  padding: 10px;  
}
.rowArea{
  display: flex;
  align-items: center;
}