@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';

.hourColumn {
  width: 100%;

  label {
    color: $IndyWorkPurpleNew;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 8px;

    & + div {
      margin-bottom: 10px;
    }
  }
}
