@import 'src/sass/buttons/button.module';
@import 'src/sass/themes/colors';
@import 'src/sass/modal';
@import 'src/sass/scroll-bar';
@import 'src/sass/inputs/select-styles';

.existingModalWrapper {
  composes: modalWrapper scrollBar;
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 600px;

  form {
    width: 100%;
  }
}

.emptyList {
  color: $IndyWorkGray_d;
  margin: 0;
  text-align: center;
}

.actionArea {
  align-self: flex-end;
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  width: 100%;
};

.clubList {
  composes: scrollBar;

  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}


.clubArea {
  composes: scrollBar;
  margin-bottom: 5px;
}

.titleArea {
  cursor: pointer;
  font-size: 16px;
  color: $IndyWorkPurpleNew;
  :hover {color: $white;}

  &.selected {color: $white;}
}

.employeeArea {
  max-height: 250px;
  list-style-type: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.employeeListItem {
  display: flex;
  align-items: center;

  margin: 5px 0;
  padding: 2px 0;

  img {
    flex: 0 1 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
  }
}

.nameArea {
  flex: 1 0;
  font-size: 0.90rem;
  color: $white;
  flex-direction: column;
  display: flex;

  span {
    &::first-letter {
      text-transform: capitalize;
    }
    color: $IndyWorkGray_d
  }
}

.dropDown {
  composes: selectStyles;
  flex: 0 1 150px;
  margin-right: 10px;
}
