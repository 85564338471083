@import 'src/sass/themes/colors';

.extra {
  font-size: 10px;
}

.bookingItem {  
  width: 100%;
  cursor: pointer;
  color: $white;
  padding: 10px 5px 5px 5px;
  border: 0;
  border-bottom: 1px solid $IndyWorkPurple_d;
  background-color: transparent;
  text-align: left;
  outline: none;

  &:hover {
    background-color: $IndyWorkPurple_d;
    &.disabled {
      background-color: transparent;
    }
  }

  &.disabled {
    cursor: default;
    color: $DarkGrey;
  }
}

.topArea {
  color: $white;
  display: flex;
  justify-content: space-between;
  font-size: 0.95rem;

  &.disabled {color: $DarkGrey;}
}
