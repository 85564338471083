@import '../../../sass/themes/colors';

.info {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 1em;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $IndyWorkGray_d_50;
  color: $IndyWorkGray_dd;
}
.actionRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}