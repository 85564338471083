@import 'src/sass/themes/colors';
@import 'src/sass/heading';

.groupDetailHeading {
  composes: h1Heading;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: flex;
  align-items: center;

  svg {
    color: $IndyWorkGray_d;
    font-size: 20px;
  }
}

.groupDetailSubHeading {
  color: $white;
  margin: 0;
  grid-area: name;

  svg {
    width: 30px;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      color: $IndyWorkPurple_d;
    }
  }
}
