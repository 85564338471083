@import 'src/sass/buttons/button.module';

.courseForm {
  margin-top: 30px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 30px;
  grid-template-columns: 150px 200px 1fr;
  grid-template-rows: 80px 80px 80px 150px;

  grid-template-areas:
          'title title categories'
          'videoUrl videoUrl videoUrl'
          'imgUrl imgUrl imgUrl'
          'img description description'
          'btnRow btnRow btnRow';

  textarea {
    height: 100%;
  }
}

.courseActionRow {
  display: flex;
  grid-area: btnRow;
  justify-content: space-between;
  margin-top: 40px;
  button {
    width: 120px;
  }
}

.imagePreviewWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.videoImg {
  grid-area: img;
  height: auto;
  max-height: 150px;
}

.video {
  display: none;
}

.buttonWithSpinner {
  display: flex;
  justify-content: center;
}
