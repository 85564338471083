@import 'src/sass/themes/colors';

.checkboxDropDown {
  align-self: center;
  width: 100%;
  position: relative;
  background-color: $IndyWorkNavBar;
  border: solid 1px $IndyWorkNavBar;
  border-radius: 2px;

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    display: block;
    border-top: 5px solid $inputPlaceholder;
    position: absolute;
    transition: all 300ms ease;
    top: 18px;
    right: 16px;
  }

  &.active {
    border: solid 1px $IndyWorkPurpleNew;    

    &:after {
      transform: rotate(180deg);
    }
  }

  .disabled {
    font-style: italic;
    background-color: $ScrollBar;
    cursor: not-allowed;
  }

  button {
    outline: none;
    position: relative;
    color: $inputPlaceholder;
    cursor: pointer;    
    font-size: 14px;
    padding: 12px 8px;
    width: calc(100% - 30px);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
  }
}
