@import "src/sass/heading";
@import "src/sass/scroll-bar";
@import "src/sass/error-styles";
@import "src/sass/themes/colors";

.photoVerificationList {
  composes: scrollBar;
  height: 100%;
}

.verificationLoadingRow {
  color: $IndyWorkWhite_d;
  margin: 80px auto 160px;
  font-size: 24px;
  position: relative;
}

.verificationItem {
  display: flex;
}

.verificationImgCol {
  flex: 0 1 150px;

  img {
    width: 120px;
    border-radius: 5px;
    padding: 10px;

    &:hover {
      cursor: pointer;
      background-color: $WorkerPink;
    }
  }
}

.verificationDancerInfo {
  flex: 0 1 400px;
  width: 400px;
}

.verificationCodeCol {
  flex: 1 0;
}

.dataRow {
  display: flex;
  font-size: 16px;
  margin-top: 8px;

  label {
    color: $IndyWorkGray_d;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 1rem;
    flex: 0 1 200px;
  }
}

.name {
  color: $IndyWorkWhite_d;
  font-weight: bold;
}

.sentence {
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0;
  color: $IndyWorkWhite_d;
}

.code {
  color: $IndyWorkPurpleNew;
}
.rejection {
  color: $IndyWorkRed;
}
.type {
  &::first-letter {
    text-transform: capitalize;
  }
}
