@import 'src/sass/themes/colors';

.navLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $IndyWorkGray_d;
  margin: 10px 0;
  font-size: 16px;

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  &.purple {
    color: $IndyWorkPurpleNew;
  }

  svg {
    margin-right: 10px;
    color: $IndyWorkGray_d;
  }

  &:hover {
    color: white;
    svg { color: white; }
  }

  &.active {
    color: white;
    svg { color: white; }
  }
}
