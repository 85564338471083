@import '../../sass/themes/colors';

.preLoginInputField {
  margin-bottom: 20px;

  label {
    color: $IndyWorkBlack_l;
    font-weight: normal;
    text-align: left;
  }

  input {
    height: 32px;
    margin-bottom: 10px;
    border-width: 0 0 1px 0;
    border-radius: 0;
    border-color: rgb(107, 156, 219);
    background: transparent;
    color: $white;

    :focus,
    :active,
    :hover {
      border-color: $white;
      border-width: 0 0 2px 0;
      box-shadow: none;
    }
    .error {
      border-color: $white;
    }
  }

  div {
    color: $WorkerPink;
  }
}
