.bookingModalPromoRow {
  display: grid;
  grid-area: promoGrid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 200px;
  grid-template-areas:
          'promoName'
          'promoDesc';

  textarea {
    height: 170px;
  }
}
