@import 'themes/colors';

.scrollBar {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: $ScrollBar;
  }

  &::-webkit-scrollbar-thumb {
    background: $IndyWorkWhite_d;
  }
};

.textAreaScroll {
  &::-webkit-scrollbar {
    width: 3px;
    height: 1px;
    background: $ScrollBar;
  }

  &::-webkit-scrollbar-thumb {
    background: $ScrollBarThumb;
  }
}

