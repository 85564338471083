@import 'src/sass/modal';
.modalVideoPlayer {
  composes: modalWrapper;
  width: 100%;
  max-width: 800px;

  h3 {
    padding: 20px 0 0 20px;
  }
}

