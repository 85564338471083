@import 'src/sass/themes/colors';

.dancerNameFilter {
  position: relative;
  margin: 0 0 0 20px;
  transition: all ease .4s;
  flex: 0 1 200px;
  align-self: center;

  input {
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 0.9rem;
    border: none;
    background-color: $IndyWorkNavBar;
    color: $IndyWorkGray_d;
    width: 100%;
    align-self: center;
    min-width: 250px;
  }

  svg {
    color: $IndyWorkPurpleNew;
    font-size: 16px;
    position: absolute;
    right: 11px;
    top: 13px;
  }
}
