
%groupGrid {
  padding: 8px;
  justify-content: center;
  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  cursor: pointer;
}

.smGroupGrid {
  @extend %groupGrid;
  grid-template-areas:
          'a a'
          'a a';
}

.lgGroupGrid {
  @extend %groupGrid;
  grid-template-areas:
          'a a a'
          'a a a'
          'a a a';
}


.groupImgWrapper {
  position: relative;
  pointer-events: none;
  user-select: none;
  img {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    display: block;
  }

  div { //darkened overlay
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
}
