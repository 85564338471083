@import 'src/sass/themes/colors';

.verificationItem {
  display: flex;

  &:hover {
    cursor: pointer;
    background-color: $IndyWorkPurple_d;
  }
}

.verificationImgCol {
  flex: 0 1 150px;

  img {
    width: 120px;
    border-radius: 5px;
    margin: 10px;
  }
}

.verificationDancerInfo {
  flex: 1 1 400px;
  width: 400px;
}

.verificationCodeCol {
  flex: 1 0;
}

.dataRow {
  display: flex;
  font-size: 16px;
  margin-top: 8px;

  label {
    color: $IndyWorkGray_d;
    font-weight: normal;
    margin-bottom: 0;
    flex: 0 1 100px;
  }
}

.name {
  color: $IndyWorkWhite_d;
  font-weight: bold;
}

.sentence {
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0;
  color: $IndyWorkWhite_d;
}

.code { color: $IndyWorkPurpleNew; }
.rejection { color: $IndyWorkRed; }
.type { &::first-letter {
  text-transform: capitalize;
} }
