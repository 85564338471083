@import 'src/sass/themes/colors';
@import 'src/sass/scroll-bar';

.groupDetailAside {
  composes: scrollBar;
  flex: 0 1 320px;
  padding: 30px 0 0 25px;
  border-left: solid 1px $IndyWorkPaneDivider;
  max-height: calc(100vh - 230px);
}
