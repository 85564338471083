@import 'src/sass/buttons/button.module';

.top {
  display: flex;
  margin-bottom: 20px;
}

.actionArea {
  text-align: right;

  button:last-child {
    margin-left: 20px;
  }
}
