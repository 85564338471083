.groupNameForm {
  display: flex;
  align-items: flex-start;

  label { margin: 15px 10px 0 0; }

  input {
    width: 300px;
  }

  button {
    margin-top: 5px;
    margin-left: 5px;
    background-color: transparent;
    border: 0;
    padding: 0;
    align-self: flex-start;

    svg {
      margin-top: 4px;
      font-size: 24px;
    }
  }
}
