%overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: 10;
  pointer-events: none;
}

.lightOverlay {
  @extend %overlay;
  background-color: rgba(255, 255, 255, 0.3);
}

.darkOverlay {
  @extend %overlay;
  background-color: rgba(0, 0, 0, 0.3);
}
