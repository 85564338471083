@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';

.bugModalWrapper {
  composes: modalWrapper;
  position: fixed;
  transition: all ease .4s;
  overflow-y: unset;
  padding: 40px;
  width: 390px;
}

.reportActionRow {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}
