@import 'src/sass/themes/colors';

.selectStyles {
  background-color: $ScrollBar;
  color: $white;
  border: 1px solid transparent;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 12px;
  padding: 0.25em 0.5em 0.25em 0.35em;
  transition: opacity 0.2s;
  height: 35px;
  width: 100%;

  &:not(&:disabled) {
    opacity: 0.7;
    :hover { opacity: 1; }
  }

  &:disabled {
    color: $IndyWorkGray_d;
  }
}
