@import 'src/sass/scroll-bar';
@import 'src/sass/buttons/button.module';

.emailList {
  composes: scrollBar;
  max-height: 310px;
  margin: 30px 0 0;
  padding: 0 20px 0 0;
}

.btnArea {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;

  button { width: 120px; }
}


.blueBtn {
  composes: filledBtnBlue;
  padding: 8px 16px;
  margin-right: 20px;
}
