@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';
@import 'src/sass/inputs/text-input.module';

.emailListItem {
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}

.inputRow {
  display: flex;
  width: 100%;
}

.emailInputLabel {
  flex: 0 1 40px;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  letter-spacing: 0;
  margin: 0;
  align-self: center;
  color: $IndyWorkWhite_d;
}

.removeBtn {
  composes: invertedBtnRed;
  flex: 0 1 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 20px;

  &:hover div {
    background-color: $IndyWorkWhite_d;
  }
}

.icon {
  width: 15px;
  height: 4px;
  background-color: $IndyWorkRed;
  margin: 0 auto;
}

.errorRow {
  color: $IndyWorkRed;
  margin: 5px 0 0 45px;
  height: 15px;
}
