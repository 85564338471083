@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';
@import 'src/sass/themes/colors';

.dancerGrid {
  composes: scrollBar;
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 150px);    
}

.suspended {
  height: calc(100% - 100px);  
}

.managerSearch {
  height: calc(100% - 250px);
}

.loadingRow {
  color: $IndyWorkWhite_d;
  margin: 40px auto;
  font-size: 24px;
  position: relative;
  bottom:0;
  width: 100%;
}

.warningHeading {
  color: $IndyWorkGray_d;
  text-align: center;
}
