@import 'src/sass/themes/colors';

.headerSortSelect {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.sortSelect {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    margin-bottom: 0;
  }

  div {
    svg {
      top: 10px;
      right: 16px;
    }

    select {
      width: 120px;
      color: $IndyWorkPurpleNew;
      margin-right: 10px;
      padding: 8px;
    }
  }
}
