@import 'src/sass/themes/colors';

.navClubAreaWrapper {
  padding-top: 15px;
  border-top: 1px solid $IndyWorkPurple_d;
  margin: 1rem 0;
}

.navClubGrid {
  display: grid;
  grid-gap: 5px 10px;
  margin-bottom: 5px;
  text-decoration: none;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 20px 20px;
  grid-template-areas:
          'thumbnail name'
          'thumbnail location';

  img {
    grid-area: thumbnail;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
  }
}

.navClubName {
  grid-area: name;
  font-size: 1rem;
  font-weight: bold;
  color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navClubCity {
  grid-area: location;
  font-size: 0.75rem;
  font-weight: normal;
  color: $IndyWorkGray_d;
  &::first-letter {
    text-transform: capitalize;
  }
}

.clubChange {
  font-size: 0.75rem;
  font-weight: normal;
  color: $IndyWorkPurpleNew;
  &::first-letter {
    text-transform: capitalize;
  }
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
