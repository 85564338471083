@import '../../../../sass/themes/colors';
@import '../../../../sass/buttons/button.module';

.warnMsg {
  color: $IndyWorkGray_d;
  font-size: 1.5em;
  text-align: center;
}

.chatForm {
  flex: 0 1 60px;
  background-color: $IndyWorkNavBar;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    background-color: $IndyWorkPurple_d;
    padding: 10px;
    margin: 10px;
  }

  button {
    width: 100px;
    margin: 0 10px 0 0;
    padding: 9px;
  }
}
