@import 'src/sass/themes/colors';
@import 'src/sass/heading';
@import 'src/sass/layout-styles.module';
@import 'src/sass/table-styles';
@import 'src/sass/scroll-bar';
@import 'src/sass/error-styles';

.loadingRow {
  width: 100%;
  text-align: center;
}

.bookingTableHeader {
  composes: tableHeader;

  div:nth-child(1) { flex: 0 1 100px; }
  div:nth-child(2),
  div:nth-child(3) {
    flex: 0 1 150px;
    text-align: left;
  }
  div:nth-child(4) {
    flex: 0 1 75px;
    text-align: center;
  }
  div:nth-child(5),
  div:nth-child(6),
  div:nth-child(7) {
    flex: 0 1 150px;
    text-align: center;
  }
}

.bookingTableBody {
  composes: scrollBar;
  min-height: 200px;
  max-height: calc(100vh - 470px);

  h3 {
    padding-top: 60px;
    color: $IndyWorkGray_d;
    text-align: center;
  }
}

.bookingApplicationItem {
  display: flex;
  color: $white;
  padding: 5px 0;
  border-bottom: solid 1px $IndyWorkPaneDivider;
  text-decoration: none;

  div:nth-child(1) { flex: 0 1 100px; }
  div:nth-child(2),
  div:nth-child(3) {
    flex: 0 1 150px;
    text-align: left;
  }

  div:nth-child(4) {
    flex: 0 1 75px;
    text-align: center;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  div:nth-child(5),
  div:nth-child(6),
  div:nth-child(7) {
    flex: 0 1 150px;
    text-align: center;
  }

  &:hover {
    background-color: $IndyWorkNavBar;
  }
}
