@import 'src/sass/themes/colors';


.calLegend {
  margin-right: 40px;
}

.calLegendItem {
  display: flex;
  width: 185px;

  label {
    margin: 0 5px;
    color: white;
    font-size: 0.75rem;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.legendTop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5px;
}

.legendBottom {
  display: flex;
  justify-content: flex-start;
}


%block {
  margin: 0 5px;
  width: 20px;
  height: 20px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 4px solid;
}

.blockRed {
  @extend %block;
  border-color: $IndyWorkRed_l;
  background-color: $IndyWorkRed_l_50;
}

.blockPurple {
  @extend %block;
  border-color: $IndyWorkPurpleNew;
  background-color: $IndyWorkPurpleNew_50;
}

.blockGreen {
  @extend %block;
  border-color: $IndyWorkGreen_ll;
  background-color: $IndyWorkGreen_ll_50;
}

.blockYellow {
  @extend %block;
  border-color: $IndyWorkYellow_ll;
  background-color: $IndyWorkYellow_ll_50;
}

.blockOrange {
  @extend %block;
  border-color: #FF8100;
  background-color: #8B4600;
}

.blockPink {
  @extend %block;
  border-color: #ff6dc4;
  background-color: $WorkerPink;
}
