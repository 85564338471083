@import 'src/sass/themes/colors';

.loggedInRoutesWrapper {
  display: flex;
  margin: auto;
  height: 100vh;
  background-color: $IndyWorkPurple_dd;
  overflow: hidden;
  font-size: 14px;
}

.mainPage {
  position: relative;
  flex: 1 0;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-corner {
    background: #151626;
  }

  @media (min-width: 2400px) {
    justify-content: center;
  }
}
