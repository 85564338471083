@import 'src/sass/modal';
@import 'src/sass/buttons/button.module';

.addOrgModal {
  composes: modalWrapper;
  width: 400px;;
  min-width: 300px;
  padding: 20px;

  h3 + div {
    height: 80px;
  }
}

.buttonRow {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  button {
    width: 120px;
  }
}
