@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';

.listItem {
  width: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $IndyWorkGray_d;
  border-top: 1px solid $IndyWorkNavBar;
  background-color: transparent;

  &.active {
    @extend .listItem;
    background-color: $IndyWorkPaneDivider;
  }
}

.thumbnail {
  flex: 0 1 75px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin-right: 20px;
  padding: 0;
  img {
    border-radius: 5px;
    width: 75px;
    height: 75px;    
  }
  &.closed {
    img {
      filter:grayscale(1)
    }
  }
}

.columnsName {
  flex: 1 0;

  :first-child {
    color: $white;
    font-weight: bold;
    font-size: 16px;
  }
  :last-child {
    font-size: 14px;
  }
}

.columnsAddress {
  flex: 0 1 250px;
  user-select: all;
}

.columnsPhone {
  flex: 0 1 150px;
  user-select: all;
}

.actionArea {
  flex: 0 1 300px;
  display: flex;
  justify-content: flex-end;
  align-self: center;

  a {
    text-decoration: none;
    width: 80px;
    height: 36px;
    padding: 8px 10px;
    margin: 0 0 0 8px;
  }

  button {
    margin-left: 8px;
    padding: 8px 10px;
    min-width: 80px;
  }
}
