@import 'src/sass/buttons/modern-btn.module';
@import 'src/sass/layout-styles.module';
@import 'src/sass/heading';

.blastPageHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid $IndyWorkPaneDivider 1px;
}

.blastBtnGroup {
  display: flex;
  
  button { margin: 0 10px; width: fit-content; padding: 4px 10px }
  button:first-child { margin-right: 0; }
  button:last-child { margin-left: 0; }
}

.addMsgBtn {
  composes: modernBtn;
  width: 170px;
}

.filterBtn {
  composes: modernBtn;
  &.active {
    color: $IndyWorkPurpleNew;
  }
}

.iconRotate {
  transform: rotate(45deg);
}
