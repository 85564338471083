@import 'src/sass/modal';
@import 'src/sass/error-styles';
@import 'src/sass/scroll-bar';
@import 'src/sass/themes/modal-constants';

.bookingModalWrapper {
  composes: modalWrapper;
  position: fixed;
  transition: all ease .4s;
  overflow-y: unset;

  &Big {
    composes: bookingModalWrapper;
    padding: 30px 40px 40px;    
  }

  &Small {
    composes: bookingModalWrapper;
    padding: 40px;
    width: 390px;
  }
}

.bookingModalError {
  composes: modalWrapper errorMessage;
  padding: 40px;
  font-size: 24px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: 'center';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0
}