@import 'src/sass/layout-styles.module';
@import 'src/sass/buttons/button.module';
@import 'src/sass/heading';

.userProfilePage {
  composes: pageWrapper;

  header { display: flex; }
  h2 { margin-bottom: 20px; }
  label { font-size: 14px; }
}

.profileForm {
  margin-top: 40px;
  display: flex;

  form {
    display: grid;
    grid-template-areas:
      'formLabel formLabel'
      'name email'
      'password confirm'
      'actions actions';
    grid-template-columns: 250px 250px;
    grid-template-rows: 30px 70px 70px 32px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    h2 {
      grid-area: formLabel;
      margin: 0;
    }
  }
}

.imgArea {
  display: flex;
  flex-direction: column;
  width: 220px;
  position: relative;
  margin-right: 60px;

  h2 {
    margin: 0 0 20px;
    text-align: center;
  }

  div {
    height: 220px;
    width: 220px;
  }

  img:hover svg {
    opacity: 1;
  }

  svg {
    opacity: 1;
    right: 5px;
    bottom: 25px;
    height: 45px;
    width: 45px;
    padding: 10px;
  }
}

.textFields {
  display: flex;
  flex-direction: column;

  label { margin-bottom: 10px; }
}

.actionRow {
  grid-area: actions;
  margin-left: auto;
  button {
    width: 115px;
    height: 40px;
    font-size: 14px;
    margin: 20px 0 0 20px;
  }
}
