@import 'src/sass/themes/colors';

.stagedCheckbox {
  height: 20px;
  width: 20px;
  margin: 0;
  margin-right: 10px;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  border: solid 1px $IndyWorkCB_gray;

  &:hover input ~ span {
    background-color: #ccc;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    background-color: $IndyWorkWhite_d;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 2px;

    &:after {
      content: '';
      position: absolute;
      display: block;
    }
  }
}

.checked {
  &:after {
    left: 5px;
    top: 1px;
    width: 10px;
    height: 15px;
    border-right: solid 5px $IndyWorkPurple_l;
    border-bottom: solid 5px $IndyWorkPurple_l;
    transform: rotate(45deg);
  }
}

.indeterminate {
  &:after {
    left: 2px;
    top: 8px;
    width: 16px;
    height: 5px;
    border-radius: 1px;
    background-color: $IndyWorkPurple_l;
  }
}

.filled {
  &:after {
    left: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 1px;
    background-color: $IndyWorkPurple_l;
  }
}
