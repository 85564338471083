@import 'src/sass/modal';
@import 'src/sass/scroll-bar';

.addToGroupModal {
  composes: modalWrapper scrollBar;
  min-width: 425px;
  max-width: 530px;
  width: 100%;
  height: auto;
  min-height: 400px;
}
