.bookingModalEventRow {
  display: grid;
  grid-area: eventGrid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 250px;
  grid-template-rows: 100px 200px;
  grid-template-areas:
          'eventName eventImage'
          'eventDesc eventImage';

  textarea {
    height: 170px;
  }
}
