@import 'src/sass/themes/colors';

.textCountSelected {
  color: $white;
  font-size: 14px;
  &::first-letter {
    text-transform: capitalize;
  }

  span {
    color: $IndyWorkPurpleNew;
    font-weight: bold;
    margin: 0 3px;
  }
}
