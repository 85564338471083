@import 'src/sass/themes/colors';

.clubLogoWrapper {
  position: relative;
  padding-left: 15px;;

  img {
    border-radius: 100%;
    height: 130px;
    width: 130px;
  }

  &.disabled {
    cursor: default !important;
    img {
      cursor: default;
    }
  }
}
