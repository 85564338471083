@import 'src/sass/themes/colors';
@import 'src/sass/scroll-bar';

.noBookingWrapper {
  color: $IndyWorkGray_d;
  text-align: center;
  margin-bottom: 40px !important;
}

.bookingListWrapper {
  composes: scrollBar;
  margin: 0 0 20px;
  padding: 0;
}
