@import 'themes/colors';
@import 'scroll-bar';

.tableHeader {
  display: flex;
  color: $IndyWorkPurpleNew;
  font-size: 14px;
  &::first-letter {
    text-transform: capitalize;
  }
  margin-bottom: 10px;
}

.tableBody {
  composes: scrollBar;
}

.tableItem {
  display: flex;
}
