@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.groupList {
  composes: scrollBar;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.gridItem {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  pointer-events: none;
}

.group {
  background-color: $IndyWorkNavBar;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 0;
  pointer-events: auto;
  user-select: none;
  margin-bottom: 20px;
  height: 220px;
  width: 220px;
  a > div {
    height: 204px;
  }
  &:hover {
    background-color: $ScrollBar;
    & > .groupBottomRow {
      background-color: $ScrollBar;
    }
  }
}

.groupBottomRow {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $IndyWorkNavBar;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: 8px;
  &:active { cursor: grabbing; }

  svg {
    color: #818390;
    padding: 7px 5px 10px;
    font-size: 36px;
    display: block;
    cursor: grab;
    pointer-events: initial;
    &:active { cursor: grabbing; }
  }

  p {
    font-family: $Roboto;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    user-select: none;
    padding-bottom: 3px;
  }
}
