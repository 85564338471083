@import "~video-react/styles/scss/video-react"; // or import scss

html, body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1D1F32;
}

body > * {
  flex-shrink: 0;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input {
  padding: .5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#root, #AppRoot, .FullHeight{
  flex: 1 0;
  /*min-height:100%;*/
}

.TimeGrid {
  display: grid;
}

.TimeHeader {
  padding: 10px;
  grid-column-start: 1;
}

.TimeHourContent {
  grid-column-start: 2;
}

.TimeRow {
  border-top: 1px solid black;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
}

.app-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.app-content-right{
  border-left: 1px solid black;
  padding: 20px;
  flex: 2;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  height: 100%;
  font-size: 0.85em;
}

.app-content-left{
  padding: 20px;
  flex: 3.2;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid black;
  display: flex;
  height: 100%;
}

.user-fetching-error-message {
  text-align: center;
  color: #a6a5b6;
  font-size: 18px;
  font-weight: 400;
}

.margin-top-20 {
  margin-top: 20px;
}

.mobile-toast {
  background: #3E3E66 !important;
}

.mobile-toast-body {
  background: #3E3E66;
  color: #DCE1E1;
}

.mobile-toast-progress-bar {
  background: #EC008C !important;
}
