@import 'src/sass/themes/colors';

.searchInput {
  position: relative;
  align-self: center;
  width: 100%;

  input {
    padding: 0.75em 2.75em 0.75em 1.25em;
    font-size: 0.9rem;
    border-radius: 20px;
    border: none;
    background-color: $IndyWorkPurple_d;
    color: $IndyWorkGray_d;
    width: 100%;

    &:disabled {
      background-color: #24243e;
      cursor: not-allowed;
    }
  }

  svg {
    font-size: 18px;
    color: $IndyWorkGray_d;
    position: absolute;
    right: 13px;
    top: 13px;
  }
}
