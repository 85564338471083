@import 'src/sass/themes/colors';

$LG_SQUARE: 220px;
$SM_SQUARE: 130px;

%dancerGridItem {
  color: $white;
  margin: 14px;
  height: auto;
  transition: all 0.2s;


  img {
    border-radius: 1px;
    display: block;
    max-width: 100%;    
    transition: all 300ms ease;
    &.canSelect {
      cursor: pointer;
      &:hover {
        border: 4px solid $IndyWorkPurpleNew;
      }
    }

    &.selected {
      border: 4px solid $IndyWorkPurpleNew;
    }

    &.blocked {
      filter: grayscale(1.0);
    }

    &.inGroup {
      border: 1px solid $IndyWorkGreen;
    }
  }
}

.dancerGridSm {
  @extend %dancerGridItem;
  width: $SM_SQUARE;
  flex: 0 1 $SM_SQUARE;
  img {
    width: $SM_SQUARE;
  }
}

.dancerGridLg {
  @extend %dancerGridItem;
  width: $LG_SQUARE;
  flex: 0 1 $LG_SQUARE;
  img {
    width: $LG_SQUARE;
    height: $LG_SQUARE;
    line-height: $LG_SQUARE;
    text-align: center;       
  }
}

.imgWrapper {
  position: relative;
  background-color: rgba(0,0,0,0.5);  
  svg {
    font-size: 12px;
  }
}

.checkboxWrapper {
  position: absolute;
  border-radius: 2px;
  top: 8px;
  left: 8px;
  z-index: 1;
  
  input ~ span:after {
    border-color: $IndyWorkBlack_d;
  }
}

.delete {
  color: $IndyWorkRed;
  position: absolute;
  font-size: 16px;
  margin-bottom: 4px;
  margin-right: 4px;
  bottom: 4px;
  right: 4px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    font-size: 24px;
  }
}

.nameWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.name {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

%email {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailLg {
  @extend %email;
  width: $LG_SQUARE;
}

.emailSm {
  @extend %email;
  width: $SM_SQUARE;
}
