@import 'src/sass/heading';
@import '../../../../../sass/layout-styles.module';
@import 'src/sass/themes/colors';

.groupNotificationHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid $IndyWorkPaneDivider 1px;

  div { width: 320px; }
}
