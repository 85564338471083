@import 'src/sass/scroll-bar';
@import 'src/sass/themes/colors';

.clubGrid {
  composes: scrollBar;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0;
}


.clubList {
  composes: scrollBar;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: overlay;
  flex: 1 0;
  height: calc(100% - 150px);
}

.headerListItem {
  width: 100%;
  display: flex;
  margin: 2px 0;
  padding: 0 20px;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  color: $IndyWorkPurpleNew;
  justify-content: center;
  background-color: transparent;
  border: none;
  flex: 0 1 26px;

  :hover {
    cursor: default;
    background-color: transparent;
  }
}

.colImg {
  flex: 0 75px;
  margin-right: 20px;
}

.colName {
  flex: 1 0;
}

.colAddress {
  flex: 0 1 250px;
}

.colPhone {
  flex: 0 1 150px;
}

.colActions {
  flex: 0 1 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;

  button {
    width: 80px;

    :first-child {
      margin-bottom: 5px;
    }
  }
}
