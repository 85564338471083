@import 'src/sass/buttons/button.module';

.subHeaderWrapper {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}

.selectedArea {
  flex: 1 0;
  display: flex;
}

.dancerActionArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: center;

  button {
    margin-right: 20px;
  }
}
