.imageArea {
  display: flex;
}

.carouselArea {
  width: 250px;

  :global(.image-gallery-icon) svg {
    height: 60px;
    width: 20px;
  }
  :global(.image-gallery-bullets) {
    bottom: 10px;
  }
}

.centerWrapper {
  flex-direction: column;
}

.defaultImg {
  width: 250px;
  height: 250px;
  display: block;
  margin: auto;
}

.testImg {
  width: 250px;
  height: 250px;
  display: block;

  img {
    width: 100%;
    vertical-align: top;
    border: 0;
  }
}

.sentenceArea {
  text-align: center;
  margin: 10px;
  flex: 1;
  h4 {
    margin: 0;
    color: #6488DA;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  p {
    margin: 0 0 10px;
    flex: 1 0;
    font-size: 1rem;
    color: white;
  }
}
