@import 'src/sass/heading';
@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';

.Header {
  display: flex;
  align-items: center;
  margin: 0 0 55px 0;
  flex: 0 1 41px;
  justify-content: space-between;
}

.HeadingLeft {
  display: flex;  
}

.HeadingRight {
  display: flex;
  gap: 0.5em;
  div { width: 240px; }
  button { right: 20px; }
}

.IconBtn {
  background-color: transparent;
  border: 0;
  font-size: 30px;

  :hover {
    color: $IndyWorkPurpleNew;
  }

  svg {
    padding-top: 4px;
    color: $IndyWorkGray_d;
  }

  &.active {
    svg {color: $IndyWorkPurpleNew;}
  }
}

.AddClubLink{
  composes: invertedBtnGreen;
  margin: 0 10px 0 0;
  text-decoration: none;
  padding: 8px 16px;
}

.AddOrgBtn {
  composes: invertedBtnGreen;
  margin: 0;
}
