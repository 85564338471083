@import 'src/sass/buttons/modern-btn.module';
@import 'src/sass/themes/colors';

.sortBtnGroup {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    margin-bottom: 0;
  }

  div {
    svg {
      top: 10px;
      right: 16px;
    }

    select {
      width: 150px;
      color: $IndyWorkPurpleNew;
      margin-right: 10px;
      padding: 8px;
    }
  }

  button {
    width: 80px;
    margin-right: 10px;
  }
}
