.groupDetailHeader {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 480px;
  grid-row-gap: 20px;
  grid-template-areas:
          'name actions'
          'counts filter';
}

.groupDetailActionRow {
  display: flex;
  justify-content: space-between;
  grid-area: actions;
  button {
    margin: 0 5px;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
}
