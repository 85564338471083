@import 'src/sass/modal';
@import 'src/sass/error-styles';
@import 'booking-label-styles.modules';


.bookingFormCheckboxWrapper {
  position: relative;
  align-self: center;
  padding-top: 12px;
  display: flex;

  label {
    color: $modalInputColor;
    padding-top: 2px;
    span {
      background-color: $modalInputBg;
    }
  }


  &.disabled {
    label {
      color: $modalLabelColorDisabled;
    }
  }

  svg {
    cursor: pointer;
    margin-left: 5px;
    align-self: center;
    margin-top: 3px;

    &:hover {
      & ~ h6 {
        display: block;
        position: absolute;
        background-color: $IndyWorkPurple;
        padding: 5px 10px;
        width: 200px;
        left: -100px;
        bottom: 10px;
        border-radius: 4px;
        font-size: 12px;
        &:after {
          top: calc(100% - 1px);
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid $IndyWorkPurple;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          margin-left: -7px;
          right: 2px;
        }
      }
    }
  }

  h6 {
    display: none
  }
}


