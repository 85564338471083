@import '../../../../sass/layout-styles.module';
@import '../../../../sass/heading';
@import '../../../../sass/themes/colors';
@import '../../../../sass/buttons/button.module';

.invitesPageHeader {
  display: flex;
  margin-bottom: 20px;

  button {
    margin-left: 20px;
  }

  .invitesSearchBar {
    width: 260px;
    margin-left: 80px;
  }

  .invitesHeaderSelect {
    display: flex;
    align-items: center;
    margin-left: auto;

    label {
      margin-right: 10px;
      margin-bottom: 0;
    }

    div {
      svg {
        top: 10px;
        right: 16px;
      }

      select {
        width: 150px;
        color: $IndyWorkPurpleNew;        
        padding: 8px;
      }
    }
  }
}

.inviteList {
  margin: 0;
  padding: 0;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
}

.invitesTableHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  margin-bottom: 15px;
  font-size: 12px;
  color: $IndyWorkPurpleNew;
  border: none;
  &::first-letter {
    text-transform: capitalize;
  }
  div:nth-child(1) {
    flex: 1 0;
    margin-left: 10px;
  }
  div:nth-child(2) {
    flex: 0 1 200px;    
    text-align: center;
  }
}
