@import 'src/sass/themes/colors';

.dropdownList {
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.8);
  max-height: 180px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: $IndyWorkNavBar;
  position: absolute;
  width: max-content;
  max-width: 200%;
  min-width: 100%;

  li {
    padding: 10px;
    display: flex;
    font-size: 14px;
    letter-spacing: 0;
    cursor: pointer;
    border-bottom: solid 1px $IndyWorkPaneDivider;

    &:hover {
      background-color: $ScrollBarThumb;
    }

    &.active {
      background-color: $activeCheckboxDropDownItem;
      border-bottom: solid 1px $checkboxDropDownDivider;
      &:hover {
        background-color: $checkboxDropDownDivider;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    &.disabled {
      cursor: default;
      color: $DarkGrey
    }

    label {
      margin-right: 10px;
    }
  }
}
