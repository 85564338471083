@import 'src/sass/scroll-bar';

.groupListWrapper {
  margin-top: 20px;
  flex: 1 0;
  display: flex;
  flex-direction: column;

  button { align-self: flex-start; }
}

.groupDancerList {
  composes: scrollBar;
  display: flex;  
  margin-top: 10px;
  flex-flow: row wrap;  
  height: calc(100vh - 275px);
  overflow-y: scroll;

  &::after {
    content: "";
    flex: auto
  }
}
