@import '../../../../sass/themes/colors';
@import '../../../../sass/themes/fonts';

.inputFormCheckbox {
  display: flex;
  position: relative;

  input {
    display: none;
    user-select: none;
  }

  label {
    cursor: pointer;
    display: flex;
    color: $IndyWorkCB_gray;
    font-size: 16px;
    font-weight: normal;
    font-family: $Roboto;
    margin: 0;
    align-self: flex-start;
    align-items: center;

    span {
      background-color: $IndyWorkPurple_d;
      border: solid 1px $IndyWorkPurple_ldl;
      display: block;
      user-select: none;
      height: 16px;
      width: 16px;
      border-radius: 2px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  input:checked + label span {
    background-color: $IndyWorkPurpleNew;
    border: solid 1px $IndyWorkPurpleNew;
    color: white;
    &:after {
      content: ' ';
      left: 5px;
      top: 5px;
      position: absolute;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:disabled + label span {
    color: $IndyWorkPurple;
    cursor: not-allowed;
    background-color: $ScrollBar;
    border: solid 1px $ScrollBar;

    &:after {
      border: solid $IndyWorkGray_dd;
      border-width: 0 2px 2px 0;
    }
  }
}
