@import 'src/sass/modal';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/modal-constants';

.icons {
  font-size: 16px;
  cursor: pointer;
  color: $white;
  margin: 0 6px 0 0;

  :hover {
    color: $IndyWorkPurpleNew;
  }
}

.bookingModalHeader {
  margin-bottom: 20px;

  p {
    color: $IndyWorkGray_d;
    margin: 0;
  }
}

.secondRow {
  display: flex;

  h3 {
    margin: 0;
    align-self: center;
  }

  svg {
    cursor: pointer;
    color: $white;

    :hover {
      color: $IndyWorkPurpleNew;
    }
  }
}

.bookingIconArea {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-self: flex-start;
  font-size: 14px;
  margin-top: 8px;

  a {
    font-size: 19px;
    margin-left: 5px;
  }

  & > svg:last-child {
    margin-left: 5px;
  }
}
