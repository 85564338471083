@import 'src/sass/themes/colors';

.dancerStyles {
  display: flex;
  position: relative;
  flex: 1 0;
  z-index:3;

  span {
    flex: 0 1;
    align-self: center;
  }
}

.leftWrapper {
  position: relative;
  align-self: center;
  display: flex;
}
