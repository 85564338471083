/* Variables */
$white: #ffffff;
$IndyWorkWhite_d: #F2F2F2;
$IndyWorkGray_dd: #ABADB9;
$IndyWorkPurple_dd: #181a2a;
$IndyWorkPurple_ddd: #1e1e31;
$IndyWorkNavBar: #272741;
$IndyWorkPaneDivider: #31314D;
$IndyWorkPurple_d: #393960;
$ScrollBarThumb: #3E3E66;
$IndyWorkPurpleNew_50: #6488DA70;
$IndyWorkPurple_ldl: #4C4C80;
$IndyWorkPurple_ll: #6b6e96;
$IndyWorkPurple_l: #4c4c80;
$IndyWorkPurple: #5B607E;
$IndyWorkLightPurple: #B3B7EE;
$IndyWorkGray_d: #828282;
$IndyWorkGray_d_50: #82828270;
$IndyWorkGray_l: #BDBDBD;
$IndyWorkRust: #571246;
$IndyWorkOrange_l_50: #8B4600;
$IndyWorkOrange_l: #FF8100;
$IndyWorkRed_l_50: #FF454170;
$IndyWorkRed_ll: #B40400;
$WorkerPink_d: #9c005d;
$WorkerPink_l: #bd1b7b;
$hoverRed: #d20500;
$WorkerPink: #EC008C;
$IndyWorkRed_l: #FF4541;
$Error: #E76464;
$IndyWorkRed: #EB5757;
$IndyWorkYellow: #F2C94C;
$IndyWorkYellow_ll: #ffe11e;
$IndyWorkYellow_ll_50: #d2c03a;
$IndyWorkGreen_ll_50: #1DCF3270;
$deepGreen: #12a71d;
$IndyWorkGreen_ll: #1DCF32;
$IndyWorkWorkerGreen: #54AB68;
$IndyWorkGreen: #27AE60;
$IndyWorkGreen_d: #81BF6C;
$IndyWorkBlue: #439ACE;
$IndyWorkPurpleNew: #6488DA;
$WebBusinessBlue_l: #375DD7;
$WebBusinessBlue_d: #1F3785;
$activeBlue: #0842ff;
$ScrollBar: #1D1F32;
$IndyWorkBlack_d: #333333;
$DarkGrey: #999;
$IndyWorkCB_gray: #818390;
$IndyWorkBlue_ddd: #20253B;
$IndyWorkBlack_l: #4F4F4F;
$IndyWorkOffWhite: #DCE1E1;
$IndyWorkBlue_dddd: #282935;
$standByOrange: #FF7300;
$inputPlaceholder: #888BB0;
$uncheckedCheckbox: #414163;
$checkboxDropDownDivider: #42426C;
$activeCheckboxDropDownItem: #353555;
$TransparentBlack: rgba(0, 0, 0, 0.3);
