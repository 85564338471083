@import 'src/sass/buttons/button.module';
@import 'src/sass/modal';

.modal {
  composes: modalWrapper;
  width: 500px;
  min-width: 300px;
  max-height: 100vh;
  padding: 20px;
}

.buttonRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.message {
  margin-bottom: 2em;
}