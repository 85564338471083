@import 'src/sass/themes/colors';

.basicInfo {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px 150px 150px;
  grid-template-rows:    auto;
  grid-column-gap: 30px;
  grid-row-gap: 35px;
  grid-template-areas:
          'logo clubName clubName organization organization gap'
          'logo clubOptions clubOptions under21Allowed under21Allowed gap'
          'imgGrid imgGrid imgGrid imgGrid imgGrid gap'
          'address address city city state zip'
          'phone phone url url gap2 gap2';
}

.clubOptions {
  grid-area: clubOptions;
  display: flex;
  flex-direction: column;

  div {
    &:last-of-type {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        color: #FFC72C;
      }
    }
  }
}

.imgGrid {
  grid-area: imgGrid;
  text-align: center;
  border: dotted 1px #393960;
  background-color: #1D1F32;
}

.under21Allowed {
  grid-area: under21Allowed;
}

