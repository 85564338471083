@import 'src/sass/themes/colors';

.formCheckbox {
  display: block;
  position: relative;
  padding-left: 22px;
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  color: $white;

  &:hover input ~ .checkMark {
    background-color: #ccc;
  }

  &.disabled {
    cursor: not-allowed;
    color: $IndyWorkPurple;

    &:hover input:disabled ~ .checkMark {
      background-color: $IndyWorkPurple_d;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;

    &:checked ~ .checkMark {
      background-color: $IndyWorkPurpleNew;

      &:after {
        display: block;
      }

      &:hover {
        background-color: $IndyWorkPurple_l;
      }
    }

    &:disabled ~ .checkMark {
      background-color: $IndyWorkPurple_d;
    }
  }
}

.checkMark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: $IndyWorkPurple_d;
  //border: solid 1px $IndyWorkPurpleNew;

  &:after {
    content: '';
    position: absolute;
    display: none;
    border-radius: 3px;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
