@import '../themes/colors';

.modernBtn {
  cursor: pointer;
  &::first-letter {
    text-transform: capitalize;
  }
  font-size: 12px;
  border-radius: 2px;
  width: 100%;
  padding: 8px 0;
  align-self: center;

  justify-content: center;
  align-items: center;
  border: solid 1px $IndyWorkPurpleNew;
  background-color: transparent;
  color: $white;
  display: flex;

  &:hover {
    color: $IndyWorkPurple_d;
  }

  svg {
    margin-right: 5px;
    font-size: 14px;
  }

  &:disabled {
    color: $IndyWorkGray_d;
    border-color: $IndyWorkPurple_l;
    cursor: not-allowed;
  }
}
