@import 'src/sass/themes/colors';

.weeklyBookingGrid {
  margin: 3px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: 20px;
  grid-template-areas:
          'personIcon'
          'time'
          'statusLabel'
          'statusText'
          'repeat';
}

.dailyBookingGrid {
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 125px;
  grid-template-rows: 30px;
  grid-row-gap: 5px;
  grid-template-areas:
          'time statusLabel'
          'statusText personIcon'
          'repeat repeat';
}

.dailyPersonIconArea {
  grid-area: personIcon;
  background-color: $TransparentBlack;
  justify-self: end;
  align-self: center;  
}

.dailyLabelArea {
  grid-area: statusLabel;
  justify-self: end;
  align-self: center;
}

.weeklyPersonIconArea {
  grid-area: personIcon;
  justify-self: end;
  align-self: center;
  display: flex;
  justify-content: space-between;
}

.iconArea {
  display: flex;
  font-size: 19px;
}

.timeArea {
  grid-area: time;
  justify-self: start;
  align-self: center;
}

.status {
  grid-area: statusText;
  justify-self: start;
  align-self: center;
  font-weight: bold;
}

.repeats {
  grid-area: repeat;
  justify-self: start;
  align-self: center;
  font-weight: normal;
  font-style: italic;
}

%label {
  width: 125px;
  height: 17px;
  color: white;
  font-size: 0.85rem;
  text-align: center;
}

.redLabel {
  @extend %label;
  background-color: $IndyWorkRed_l;
  &::after {
    content: 'UNFILLED BOOKING';
  }
}

.purpleLabel {
  @extend %label;
  background-color: $IndyWorkPurpleNew;
  &::after {
    content: 'ACTION NEEDED';
  }
}

.greenLabel {
  @extend %label;
  background-color: $IndyWorkGreen_ll;
  &::after {
    content: 'FILLED BOOKING';
  }
}
