@import 'src/sass/themes/colors';

.subLinkWrapper {
  color: $IndyWorkGray_d;
  margin: 10px 0;

  &.purple {
    svg {
      color: $white;
    }
  }

  &:hover {
    color: white;
    svg { color: white; }
    .arrow { border-top: solid 5px white; }
    button { color: white; }
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  svg {
    margin-right: 10px;
    color: $IndyWorkGray_d;
  }

  button {
    color: $IndyWorkGray_d;
    :hover { color: white; }
  }

  &.active {
    color: $white;
    svg { color: $white; }
    button { color: $white; }
  }

}

.miniNavLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $IndyWorkGray_d;
  margin: 10px 0 10px 30px;
  font-size: 14px;

  svg {
    margin-right: 10px;
    color: $IndyWorkGray_d;
  }

  &:hover { color: white; }

  &.activeLink {
    color: white;
    svg { color: white; }
  }
}

.subLinkToggle {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding-left: 0;
  width: 100%;
  text-align: left;
  color: $IndyWorkGray_d;
  cursor: pointer;
  font-size: 16px;

  .arrow {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid $IndyWorkGray_d;
    transition: all 0.3s;
    transform: rotate(180deg);
    margin-left: auto;

    &.open {
      transform: rotate(0deg);
    }
    &.hasRoute {
      border-top: 5px solid $white;
    }
  }

  &.purple {
    color: $IndyWorkPurpleNew;
  }
}
