.loadingSpinner {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
      }
  }
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
