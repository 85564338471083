@import 'src/sass/error-styles';
@import 'src/sass/layout-styles.module';
@import 'src/sass/heading';

.chatArea {
  margin-top: 30px;
  display: flex;
  max-height: calc(100vh - 108px);
  height: 90%;
}

.chatListArea {
  flex: 0 1 300px;
  display: flex;
  flex-direction: column;  
  gap:1em;  
}

.searchInput {
  input {
    width: 280px;
    margin: 0 auto;
  }
  svg {
    top: unset;
    bottom: 12px;
    right: 24px;
  }
}

.space {
  flex-basis: 48px;
  border-bottom: solid 1px $ScrollBarThumb;  
}
