@import 'src/sass/themes/colors';

.radioInput {
  cursor: pointer;
  margin: 0 5px 0 0;

  input {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      margin: 0;
      padding-left: 25px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      font-size: 12px;
      color: $IndyWorkGray_d;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid $IndyWorkPurpleNew;
        border-radius: 100%;
        background: transparent;
      }

      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }

    &:checked + label { color: $white; }
    &:checked + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $IndyWorkPurpleNew;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      transition: all 0.2s ease;

      opacity: 1;
      transform: scale(1);
    }
  }
}
