@import 'themes/colors';

.errorStyles {
  height: 16px;
  color: $IndyWorkRed;
  font-size: 10px;
  text-align: left;
  margin: 0;
}

.errorMessage {
  text-align: center;
  color: $IndyWorkRed;
}

.listError {
  @extend .errorMessage;
  width: 100%;
  font-size: 24px;
  padding-top: 40px;
}

.errorPage {
  @extend .errorMessage;
  padding-top: 40px;
}

.acceptedDancer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: $IndyWorkPurple_l;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 3px;
  }

  p {
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
