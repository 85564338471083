@import 'src/sass/themes/colors';
@import 'src/sass/error-styles';

.noBookingWrapper {
  color: $IndyWorkGray_d;
  text-align: center;
}

.loadMorebtnWrapper{
  display: flex;
  justify-content: center;
  margin: 1rem;
}
