@import 'booking-label-styles.modules';

.bookingDateWrapper {
  position: relative;
  grid-area: date;

  & > button {
    font-size: $modalInputSize;
    color: $IndyWorkWhite_d;
    border: 1px solid $modalInputBorder;
    background-color: $modalInputBg;
    cursor: pointer;
    border-radius: 3px;
    padding: 12px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    outline: none;

    &:hover {
      color: $modalInputFocusColor;
    }

    & + svg {
      color: inherit;
    }

    &:active,
    &:focus {
      border: solid 1px $IndyWorkPurpleNew;
    }

    &:disabled {
      font-style: italic;
      color: $IndyWorkGray_dd;
      background-color: $ScrollBar;
      border-color: $ScrollBar;
      cursor: not-allowed;

      svg {
        color: transparent;
      }
    }
  }

  & > div {
    position: absolute;
    z-index: 5;
    top: 0;
    background-color: $IndyWorkPurple_dd;
  }
}
