@import 'src/sass/themes/colors';

.adminNotificationItem {
  display: flex;
  padding: 16px 40px 16px 0;
  font-size: 16px;
  border-bottom: solid 1px $IndyWorkPaneDivider;

  img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    margin-right: 40px;
  }
}

.meta {
  flex: 0 1 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  color: $IndyWorkGray_dd;
  margin-right: 40px;
}

.audience {
  font-weight: bold;
  &::first-letter {
    text-transform: capitalize;
  }
  color: $white;
}

.author, .target {
  font-size: 12px;
  margin-bottom: 0;
  span { font-weight: bold; }
}

.time { font-size: 12px; }