@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

$modalBtnBg:          $IndyWorkNavBar;
$modalBtnBgHover:     $IndyWorkPurple_l;
$modalBtnColor:       rgba(255, 255, 255, .7);
$modalBtnColorHover:  rgba(255, 255, 255, 1);
$modalBtnActive:      $IndyWorkPurpleNew;
$modalBtnActiveHover: $IndyWorkPurple_l;

$modalBackground:   $IndyWorkPaneDivider;

$modalHeadingColor: $white;
$modalHeadingFont:  $Roboto;
$modalHeadingSize:  24px;

$modalLabelFontFamily: $Lato;
$modalLabelColor: $white;
$modalLabelFontSize: 12px;
$modalLabelColorDisabled: $IndyWorkPurple;
$modalLabelColorError: $IndyWorkRed_l;

$modalInputColor:          rgba(255, 255, 255, .7);
$modalInputFocusColor:     rgba(255, 255, 255, 1);
$modalInputColorDisabled:  $IndyWorkLightPurple;
$modalInputFont:           $Roboto;
$modalInputSize:           14px;
$modalInputBg:             $IndyWorkNavBar;
$modalInputBgDisabled:     rgb(24, 26, 42);
$modalInputBorder:         $IndyWorkNavBar;
$modalInputBorderDisabled: $ScrollBar;
$modalInputBorderError:    $IndyWorkRed_l;
$modalInputBorderRadius:   4px;
