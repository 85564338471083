@import 'src/sass/modal';
@import 'src/sass/themes/colors';

.emailModal {
  composes: modalWrapper;
  height: auto;
  padding: 30px 40px 40px;
  width: 600px;
  color: $IndyWorkWhite_d;
}
