@import 'src/sass/themes/colors';

.spinnerWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.spinner {
  border: 3px solid $ScrollBar;
  border-top: 3px solid $white;  
  border-radius: 50%;
  width:  50px;
  height: 50px;
  animation: spin 750ms linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
