@import 'src/sass/themes/colors';

.locationFilter {
  display: flex;
  padding: 13px 15px 13px 0;
  flex: 0 1 250px;

  span {
    align-self: center;
    margin-right: 10px;
  }

  input {
    border: none;
    border-bottom: 1px solid $IndyWorkPurpleNew;
    border-radius: 2px;
    background-color: transparent;
    color: $IndyWorkWhite_d;
    font-size: 12px;
    align-self: center;
  }
}
