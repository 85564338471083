@import 'src/sass/error-styles';
@import 'src/sass/scroll-bar';
@import 'src/sass/buttons/button.module';
@import 'src/sass/themes/fonts';

.addToGroupsForm {
  display: flex;
  flex-direction: column;
  min-height: 550px;
}

.groupSettings {
  display: flex;
  flex: 0 1 40px;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid $IndyWorkNavBar;
  div {
    width: 250px;
  }
}

.iconBtn {
  background-color: transparent;
  border: 0;
  font-size: 30px;

  :hover {
    color: $IndyWorkPurpleNew;
  }

  svg {
    padding-top: 4px;
    color: $IndyWorkGray_d;
  }

  &.active {
    svg {color: $IndyWorkPurpleNew;}
  }
}

.groupArea {
  composes: scrollBar;
  flex: 1;
  margin: 25px 0;
  &.isGrid {
    display: grid;
    grid-template: 'a a a a a';
    grid-column-gap: 24px;
    grid-row-gap: 25px;
  }
}

.group {
  cursor: pointer;
  & > div {
    border: solid 2px transparent;
    height: 140px;
    width: 140px;
    border-radius: 4px;
    background-color: $IndyWorkNavBar;
  }
  &.selected > div{
    border: solid 2px $IndyWorkPurpleNew;
  }
}
.groupName {
  display: block;
  font-size: 16px;
  font-weight: bold;
  font-family: $Roboto;
  padding-top: 8px;
}

.actionArea {
  flex: 0 1 40px;
  align-self: flex-end;
  button {
    width: 120px;
    margin-left: 16px;
  }
}
