@import 'src/sass/themes/colors';
@import 'src/sass/buttons/button.module';
@import 'src/sass/scroll-bar';

.managerHeader {
  color: $IndyWorkPurpleNew;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 10px;
  div:first-child {
    margin-left: 136px; //16 + 80 + 40
    margin-right: auto;
  }

  div:last-child {
    text-align: center;
    width: 150px;
  }
}

.mangerList {
  composes: scrollBar;
  font-size: 12px;
  color: $IndyWorkGray_dd;
  width: 100%;
  height: calc(100% - 150px);
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  border-bottom: solid 1px #31314D;
  padding: 16px;

  img {
    display: block;
    height: 80px;
    border-radius: 4px;
    margin-right: 40px;
  }
}

.colInfo {
  text-align: left;
  flex: 1 0;
  span {
    font-weight: bold;
    color: $white;
  }

  a {
    text-decoration: none;
  }
}

.colAction {
  width: 100px;
}

.editBtn {
  composes: invertedBtnBlue;
  display: block;
  padding: 8px 0;
  text-decoration: none;
}

.deleteBtn {
  composes: invertedBtnRed;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.passwordResetBtn {
  width: 100%;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
  &::first-letter {
    text-transform: capitalize;
  }
  color: #ffffff;
}

.employeeLink {
  display: block;
  color: $IndyWorkGray_dd;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $white;
  }
}
