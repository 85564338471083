@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';

.wrapper {
  position: relative;

  select {
    color: $IndyWorkWhite_d;
    font-family: $Roboto;
    background-color: $IndyWorkNavBar;
    border: 1px solid $IndyWorkNavBar;
    font-size: 14px;
    border-radius: 2px;
    padding: 12px 8px;
    transition: opacity 0.2s;
    width: 100%;
    appearance: none; //allows padding removes caret
    outline: none;


    &:focus {
      border: 1px solid $IndyWorkPurpleNew;
    }

    &:disabled {
      opacity: 1;
      font-style: italic;
      color: $IndyWorkGray_dd;
      background-color: $ScrollBar;
      border: 1px solid $ScrollBar;
      cursor: not-allowed;
    }

    &.error {
      border: 1px solid $IndyWorkRed_l;
    }
  }

  svg {
    pointer-events: none;
    position: absolute;
    top: 15px;
    right: 10px;
  }
}
